import { useRecoilValue, useSetRecoilState } from 'recoil';
import { authAtom } from '../../atom/authAtom';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD_ROUTE_BASE } from '../app/Router';
import { useCallback, useEffect } from 'react';
import { tryAuthApi } from '../../api/authApi';

// Make sure the user is not logged in. Otherwise, redirect to dashboard page.
export const useNotAuth = () => {
  const navigate = useNavigate();
  const { isAuth } = useRecoilValue(authAtom);
  if (isAuth) {
    navigate(DASHBOARD_ROUTE_BASE);
  }
};

// Make sure the user is logged in. Otherwise, redirect to dashboard page.
export const useForceAuth = () => {
  const navigate = useNavigate();
  const { isAuth } = useRecoilValue(authAtom);

  useEffect(() => {
    if (!isAuth) {
      navigate('/signup');
    }
  }, [isAuth, navigate]);

  return isAuth;
};

export const useTryAuth = () => {
  const setAuth = useSetRecoilState(authAtom);
  const pingAuth = useCallback(async () => {
    try {
      await tryAuthApi(setAuth);
    } catch (e) {}
  }, [setAuth]);

  useEffect(() => {
    pingAuth();
  }, [pingAuth]);
};
