import React from 'react';
import styled from 'styled-components';
import { Video } from './Video';
import { Callout } from './Callout';
import { Goal } from './Goal';
import { BringThemHome } from './BringThemHome';
import { SignupCta } from '../common/SignupCTA';
import { EverySecond } from './EverySecond';
import { TopPart } from './TopPart';
import { MainCTA } from './MainCTA';
import JoinWhatsappGroup from './JoinWhatsappGroup';
import { Leaderboard } from '../dashboard/leaderboard/Leaderboard';
import { LeaderboardStyle } from '../dashboard/leaderboard/LeaderboardStyle';
import { PromptModal } from './PromptModal';

const LandingPageWrapper = styled.div`
  inline-size: 100dvw;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LandingPage = () => {
  return (
    <LandingPageWrapper>
      <TopPart />
      <MainCTA />
      <Video />
      <Callout />
      <Goal />
      <LeaderboardStyle>
        <Leaderboard />
      </LeaderboardStyle>
      <SignupCta />
      <BringThemHome />
      <EverySecond />
      <JoinWhatsappGroup />
      <PromptModal />
    </LandingPageWrapper>
  );
};
