import styled, { css } from 'styled-components';
import { Timer } from '../app/Timer';
import { MEDIA_QUERIES, MobileBR } from '../common/mediaQueries';

const EverySecondWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled.div`
  font-size: 80px;
  & span {
    color: var(--red);
  }
  margin-bottom: 25px;

  ${MEDIA_QUERIES.laptop(css`
    font-size: 70px;
  `)}

  ${MEDIA_QUERIES.tablet(css`
    font-size: 55px;
  `)}

  ${MEDIA_QUERIES.smallTablet(css`
    text-align: center;
  `)}

  ${MEDIA_QUERIES.mobile(css`
    font-size: 50px;
  `)}

  ${MEDIA_QUERIES.smallMobile(css`
    font-size: 40px;
  `)}
`;

export const EverySecond = () => {
  return (
    <EverySecondWrapper>
      <Heading>
        Every second is a <MobileBR />
        waking <span>nightmare</span>
      </Heading>
      <Timer big />
    </EverySecondWrapper>
  );
};
