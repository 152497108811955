import React, { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { leaderboardAtom } from '../../../atom/leaderboardAtom';
import { getLeaderboard } from '../../../api/dashboardApi';
import {
  EmptyItemList,
  HeaderSteakStyle,
  LeaderboardDetailsStyle,
  LeaderboardListStyle,
  LeaderboardRankStyle,
  LeaderboardRecordStyle,
  LeaderboardSteakStyle,
} from './LeaderboardStyle';
import styled from 'styled-components';

const emptyList = [...Array(5)].map((n, index) => (
  <LeaderboardRecordStyle key={index}>
    <EmptyItemList></EmptyItemList>
  </LeaderboardRecordStyle>
));

const Title = styled.h3`
  text-align: center;
  color: var(--white);
`;

const emojis = [
  '🥇',
  '🥈',
  '🥉',
  '🎖️',
  '🫡',
  '🇮🇱',
  '🕎',
  '🔥',
  '🕍',
  '✡️',
  '🕊️',
  '🌈',
];

const headerRow = (
  <HeaderSteakStyle>
    <LeaderboardSteakStyle>Streak</LeaderboardSteakStyle>
    <LeaderboardSteakStyle>Total</LeaderboardSteakStyle>
  </HeaderSteakStyle>
);

const footerRow = (
  <HeaderSteakStyle>
    And many more...
  </HeaderSteakStyle>
);

export const Leaderboard = () => {
  const [{ isLoaded, records }, setLeaderboard] =
    useRecoilState(leaderboardAtom);

  const loadData = useCallback(async () => {
    const records = await getLeaderboard();
    setLeaderboard((old) => ({ ...old, records, isLoaded: true }));
  }, [setLeaderboard]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const recordsItems = useMemo(
    () =>
      records.map((i, index) => {
        const emoji = emojis[index];
        const place = emoji ?? index + 1;
        return (
          <LeaderboardRecordStyle key={index} self={i.self}>
            <LeaderboardRankStyle>{place}</LeaderboardRankStyle>
            <LeaderboardDetailsStyle>
              {i.userId.userName}
            </LeaderboardDetailsStyle>
            <LeaderboardSteakStyle>{i.longest}</LeaderboardSteakStyle>
            <LeaderboardSteakStyle>{i.total}</LeaderboardSteakStyle>
          </LeaderboardRecordStyle>
        );
      }),
    [records],
  );

  return (
    <LeaderboardListStyle>
      <Title>Caller Leaderboard</Title>
      {[headerRow, ...(isLoaded ? recordsItems : emptyList), footerRow]}
    </LeaderboardListStyle>
  );
};
