import styled, { css } from 'styled-components';
import { MEDIA_QUERIES } from './mediaQueries';

export const Button = styled.div<{
  isPlaying?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 24px;
  border-radius: 999px;
  color: var(--red);
  max-width: fit-content;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  position: relative;
  overflow: hidden;
  padding: 8px;
  box-shadow: 4px 4px 6px #cdcdcd;

  ${MEDIA_QUERIES.smallTablet(css`
    font-size: 20px;
  `)}
`;

const buttonPadding = css`
  padding: 8px 24px;
  margin: 10px auto 0 auto;
`;

export const RedButton = styled(Button)`
  ${buttonPadding}
  background-color: var(--red);
  color: var(--white);
`;

export const RedButtonCallPage = styled(Button)`
  padding: 8px 24px;
  margin-top: 10px;
  background-color: var(--red);
  color: var(--white);
`;

export const FloatingRedButton = styled(RedButton)`
  && {
    /* position: absolute; */
    /* left: 50%; */
    /* transform: translateX(-50%); */
    /* transition:
      bottom 1s ease-in-out,
      opacity 0.5s ${({ isPlaying }) => (isPlaying ? 0 : 0.5)}s ease-in-out; */

    /* ${({ isPlaying }) => css`
      bottom: ${isPlaying ? -10 : 18}%;
      opacity: ${isPlaying ? 0 : 1};

      ${MEDIA_QUERIES.laptop(css`
        bottom: ${isPlaying ? -10 : 14}%;
      `)}

      ${MEDIA_QUERIES.tablet(css`
        bottom: ${isPlaying ? -10 : 14}%;
        transform: translateX(-50%) scale(0.8);
      `)}
    `} */

    ${MEDIA_QUERIES.smallTablet(css`
      position: static;
      transform: translateX(0);
      max-width: fit-content;
      opacity: 1;
    `)}

    ${MEDIA_QUERIES.mobile(css`
      max-width: unset;
      position: relative;
      left: 18px;
    `)}
  }
`;

export const BlackTextButton = styled(Button)`
  color: var(--black);
`;
