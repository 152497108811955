import React from 'react';
import { Checkbox, CheckboxLabel } from './CellFormStyles';

export const DayCheckbox = ({
  day,
  isSelected,
  handleSelect,
}: {
  day: string;
  isSelected: boolean;
  handleSelect: () => void;
}) => (
  <div key={day}>
    <Checkbox type="checkbox" id={`day-${day}`} checked={isSelected} />
    <CheckboxLabel
      className="container"
      htmlFor={`day-${day}`}
      onClick={handleSelect}
    >
      {day}
    </CheckboxLabel>
  </div>
);
