import styled from 'styled-components';
import { Facebook, IconProps, Instagram, TikTok, Twitter } from './SocialIcons';

const Wrapper = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

interface SocialProps {
  className?: string;
}
const SocialBtn = styled.a`
  color: white;
`;

export const Social: React.FC<SocialProps & Partial<IconProps>> = ({
  height = 18,
  className,
}) => {
  return (
    <Wrapper className={className}>
      <SocialBtn href={'https://www.facebook.com/bringhomenow'} target="_blank">
        <Facebook height={height} />
      </SocialBtn>
      <SocialBtn
        href={'https://www.instagram.com/bringhomenow/'}
        target="_blank"
      >
        <Instagram height={height} />
      </SocialBtn>
      <SocialBtn href={'https://www.tiktok.com/@bringhomenow'} target="_blank">
        <TikTok height={height} />
      </SocialBtn>
      <SocialBtn href={'https://twitter.com/bringhomenow'} target="_blank">
        <Twitter height={height} />
      </SocialBtn>
    </Wrapper>
  );
};
