import { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { MEDIA_QUERIES } from './mediaQueries';

const SVG = styled.svg`
  grid-row: 1 / 4;
  grid-column: 3 / 4;
  margin: 0 auto;
  width: 100%;
  scale: 1.05;

  ${MEDIA_QUERIES.mobile(css`
    grid-column: 1/2;
    grid-row: 4/6;
    margin: 30px auto 0;
  `)}
`;

interface CounterPathProps {
  active?: boolean;
  percentage?: number;
}

export const CounterPath: React.FC<CounterPathProps> = ({
  active = false,
  percentage = 0,
}) => {
  const pathRef = useRef<SVGPathElement>(null);

  useEffect(() => {
    const { current } = pathRef;
    if (current) {
      const length = current.getTotalLength();
      current.style.strokeDasharray = length.toString();
      current.style.strokeDashoffset = active
        ? `${length - percentage * length}`
        : '0';
    }
  }, [active, percentage]);

  return (
    <SVG
      width="251"
      height="212"
      viewBox="0 0 251 212"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M31.1133 201.838C18.8525 183.15 11.6713 161.429 10.3525 139.011C9.03383 116.594 13.6288 94.3676 23.6025 74.711C33.5746 55.0579 48.5414 38.723 66.8631 27.4131C85.182 16.1049 106.197 10.2245 127.672 10.3916C149.148 10.5586 170.32 16.7695 188.913 28.4111C207.51 40.0549 222.824 56.6934 233.176 76.5735C243.528 96.4547 248.509 118.794 247.581 141.185C246.653 163.575 239.855 185.135 227.964 203.564"
        stroke={active ? 'url(#paint0_linear_186_4844)' : 'currentColor'}
        strokeWidth={active ? '20' : '10'}
        strokeLinecap="round"
        ref={pathRef}
      />
      {active && (
        <defs>
          <linearGradient
            id="paint0_linear_186_4844"
            x1="129"
            y1="51"
            x2="27.4965"
            y2="212.508"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D63E22" />
            <stop offset="1" stopColor="#FFCDC4" />
          </linearGradient>
        </defs>
      )}
    </SVG>
  );
};
