import { atom } from 'recoil';

interface ZipCodeModalAtom {
  showZipModal: boolean;
  urlParamZip: string;
}

export const zipCodeModalAtom = atom<ZipCodeModalAtom>({
  key: 'zipCodeModalAtom',
  default: { showZipModal: false, urlParamZip: '' },
});
