import React, { useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';
import { authAtom } from '../../atom/authAtom';
import { secondStepSignupApi } from '../../api/authApi';
import { SubmitButton } from '../callerForm/CellFormStyles';
import styled from 'styled-components';
import { Input } from '../common/Input';

const SubTitle = styled.div`
  margin-bottom: 15px;
  text-align: center;
`;

const PhoneCodeInput = styled(Input)`
  width: 80px;
  line-height: 40px;
  letter-spacing: 4px;
  font-size: 24px;
  margin: auto;
  display: block;
  text-align: center;

  &:focus {
    margin: auto;
  }
`;

interface PhoneCodeValidationProps {
  phoneNumber: string;
  onSuccess?: () => void;
}

export const PhoneCodeValidation = ({
  phoneNumber,
  onSuccess,
}: PhoneCodeValidationProps) => {
  const [verificationCode, setVerificationCode] = useState('');
  const [{ errorMessage }, setAuth] = useRecoilState(authAtom);

  const handleSecondStep = useCallback(async () => {
    await secondStepSignupApi(phoneNumber, verificationCode, setAuth);
    if (onSuccess) {
      onSuccess();
    }
  }, [phoneNumber, verificationCode, setAuth, onSuccess]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSecondStep();
    }
  };

  return (
    <div>
      <SubTitle>Check you phone for a verification code</SubTitle>
      <div>
        <PhoneCodeInput
          placeholder="code"
          onChange={(e) => setVerificationCode(e.target.value)}
          value={verificationCode}
          light={true}
          onKeyDown={handleKeyDown}
        />
        <SubmitButton
          className="action"
          disabled={verificationCode.length < 2}
          onClick={handleSecondStep}
        >
          Verify
        </SubmitButton>
      </div>
      <div className="error-message">{errorMessage}</div>
    </div>
  );
};
