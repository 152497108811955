import styled, { css } from 'styled-components';
import { RedButton } from '../common/Button';
import { Rep } from '../../hooks/repsData';
import { MEDIA_QUERIES } from '../common/mediaQueries';

const Cta = styled(RedButton)`
  grid-row: 1 / 4;
  grid-column: 3 / 4;
  align-self: center;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
`;

export const RepWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: 60px 1fr auto;
  column-gap: 16px;
  row-gap: 6px;
  height: 128px;
  border-bottom: 1px solid var(--gray);
  padding: 30px 30px;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    ${Cta} {
      opacity: 1;
    }
  }

  ${MEDIA_QUERIES.smallTablet(css`
    ${Cta} {
      display: none;
      grid-template-columns: 60px 1fr;
    }
  `)}

  ${MEDIA_QUERIES.mobile(css`
    padding: 30px 20px;
    grid-template-columns: 60px 1fr;
  `)}
`;

const ImageWrapper = styled.div<{ initials: string }>`
  grid-row: 1 / 4;
  align-self: center;
  width: 60px;
  border-radius: 50%;
  aspect-ratio: 1;
  object-fit: cover;
  position: relative;
  overflow: hidden;

  &::before {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '${({ initials }) => initials}';
    font-size: 20px;
    border-radius: 50%;
    border: 2px solid var(--white);
    z-index: -1;
  }
`;

const RepImage = styled.img.attrs({
  onError: (e: any) => (e.target.style.visibility = 'hidden'),
  alt: '',
})`
  width: 100%;
`;

const RepName = styled.span`
  grid-row: 1 / 2;
  font-family: var(--font-secondary);
  font-size: 20px;
  font-weight: 700;

  ${MEDIA_QUERIES.mobile(css`
    font-size: 16px;
  `)}
`;

const RepTitle = styled.span`
  grid-row: 2/3;
  font-size: 14px;
  font-family: var(--font-secondary);
  font-weight: 700;

  ${MEDIA_QUERIES.mobile(css`
    font-size: 12px;
  `)}
`;

const Party = styled.div`
  grid-row: 3/4;
  font-family: var(--font-secondary);
  font-size: 14px;
  font-weight: 400;

  ${MEDIA_QUERIES.mobile(css`
    font-size: 12px;
  `)}
`;

interface RepResultProps {
  rep: Rep;
  index?: number;
  onClick: (id: string) => void;
  style: React.CSSProperties;
}

export const RepDetails: React.FC<RepResultProps> = ({
  rep: { photo, full_name, title, first_name, _id, party },
  index = 0,
  onClick,
  style,
}) => {
  return (
    <RepWrapper
      className={'rep-wrapper'}
      style={style}
      onClick={() => onClick(_id)}
    >
      <ImageWrapper initials={first_name[0]}>
        <RepImage src={photo} />
      </ImageWrapper>
      <RepName>{full_name}</RepName>
      <Party>{party}</Party>
      <RepTitle>{title}</RepTitle>

      <Cta className={'rep-call-cta'} onClick={() => onClick(_id)}>
        Call {first_name}
      </Cta>
    </RepWrapper>
  );
};
