import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { authAtom } from '../../atom/authAtom';
import {
  validateEmail,
  validateZip,
  validDisplayName,
} from '../../util/dataValidation';
import { updateUserDetails } from '../../api/authApi';
import { Input } from '../common/Input';
import { SubmitButton } from '../callerForm/CellFormStyles';

const FormStyle = styled.div`
  max-width: 200px;
  margin: auto;
`;

const Label = styled.label`
  font-size: 20px;
  margin-bottom: 10px;
  display: block;
`;

export const UserDetailsForm = () => {
  const [{ userDetails }, setAuth] = useRecoilState(authAtom);

  const [zip, setZip] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setDisplayName(userDetails.displayName);
    setZip(userDetails.zip ?? '');
    setEmail(userDetails.email ?? '');
  }, [userDetails, setDisplayName, setZip, setEmail]);

  const isValidForm = useMemo(
    () =>
      validateZip(zip) &&
      validDisplayName(displayName) &&
      (!email || validateEmail(email)),
    [zip, displayName, email],
  );

  const handleSumit = useCallback(() => {
    if (!isValidForm) {
      return;
    }
    const update = async () => {
      updateUserDetails(displayName, zip, email, setAuth);
    };

    setIsLoading(true);
    update();
    setIsLoading(false);
  }, [setIsLoading, zip, email, displayName, setAuth, isValidForm]);

  return (
    <FormStyle>
      <div>
        <Label>User name</Label>
        <Input
          light={true}
          placeholder="Name"
          value={displayName}
          disabled={isLoading}
          onChange={(e) => setDisplayName(e.target.value)}
        />
      </div>
      <div>
        <Label>Zipcode</Label>
        <Input
          light={true}
          placeholder="Zip code"
          value={zip}
          disabled={isLoading}
          onChange={(e) => setZip(e.target.value)}
        />
      </div>
      <div>
        <Label>Email (optional)</Label>
        <Input
          placeholder="Email"
          value={email}
          disabled={isLoading}
          onChange={(e) => setEmail(e.target.value)}
          light={true}
        />
      </div>
      <div>
        <Label>Phone</Label>
        <Input
          placeholder="Email"
          disabled={true}
          light={true}
          value={userDetails.phoneNumber}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <SubmitButton disabled={isLoading || !isValidForm} onClick={handleSumit}>
        Save
      </SubmitButton>
    </FormStyle>
  );
};
