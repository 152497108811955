import styled, { css } from 'styled-components';
import { MEDIA_QUERIES } from './mediaQueries';
import React from 'react';

const SVG = styled.svg<{ big?: boolean }>`
  width: var(--size);
  height: var(--size);

  ${({ big }) => css`
    --size: ${big ? '90px' : '20px'};

    ${MEDIA_QUERIES.laptop(css`
      --size: ${big ? '80px' : '16px'};
    `)}

    ${MEDIA_QUERIES.tablet(css`
      --size: ${big ? '70px' : '16px'};
    `)}

    ${MEDIA_QUERIES.smallTablet(css`
      ${big && 'display: none;'}
    `)}
  `};
`;

interface PhoneIconProps {
  big?: boolean;
}

export const PhoneIcon: React.FC<PhoneIconProps> = ({ big }) => {
  return (
    <SVG
      viewBox="0 0 14 14"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      big={big}
    >
      <path
        d="M4.82253 1.48003C4.1124 0.884885 3.05433 0.970432 2.51553 1.72421C2.37161 1.92554 2.20107 2.16568 1.90652 2.53414C0.893229 3.80166 0.893233 5.63699 1.91212 6.90001C2.66428 7.8324 3.45291 8.77322 4.3345 9.65481C5.21609 10.5364 6.15692 11.325 7.0893 12.0772C8.35233 13.0961 10.1877 13.0961 11.4552 12.0828C11.8483 11.7685 12.0935 11.5973 12.3017 11.4486C13.0299 10.9286 13.1319 9.90866 12.5633 9.2178C12.1141 8.67188 11.6138 8.15104 11.1312 7.64264C10.7467 7.23766 10.1047 7.2112 9.66835 7.55966C9.551 7.65339 9.40528 7.77692 9.10984 8.05389C7.65997 7.19236 6.78126 6.30291 5.93543 4.87948C6.21577 4.58045 6.33941 4.43431 6.43395 4.31582C6.7818 3.87983 6.75598 3.23945 6.34859 2.85849C5.85207 2.39418 5.34677 1.91937 4.82253 1.48003Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </SVG>
  );
};
