import React from 'react';
import styled from 'styled-components';
import { Twitter, Facebook, LinkedIn } from './SocialIcons';

export const XButton = styled.a`
  align-self: center;
  cursor: pointer;
  inline-size: 20px;
  justify-content: flex-start;
`;

export const Icon = ({ network = 'FB' }) => {
  switch (network) {
    case 'FB':
      return <Facebook height={24} />;
    case 'X':
      return <Twitter height={24} />;
    case 'LI':
      return <LinkedIn height={24} />;
  }
  return <></>;
};

export const ShareToSocial = ({
  network = 'FB',
  text = '',
  url = '',
  hashtag = '',
}) => {
  const createShareLink = () => {
    let baseUrl = '';
    let params = new URLSearchParams({});
    switch (network) {
      case 'FB':
        baseUrl = 'https://www.facebook.com/sharer/sharer.php?';
        params.set('u', url);
        params.set('quote', text);
        break;
      case 'X':
        baseUrl = 'https://twitter.com/intent/tweet?';
        params.set('text', text);
        params.set('url', url);
        params.set('hashtags', hashtag);
        break;
      case 'LI':
        baseUrl = 'https://www.linkedin.com/feed/?';
        params.set('shareActive', 'true');
        params.set('text', text);
    }

    return baseUrl + params.toString();
  };

  const handleShare = () => {
    const shareLink = createShareLink();
    window.open(
      shareLink,
      '_blank',
      'noopener,noreferrer,width=550,height=420',
    );
  };

  return (
    <XButton onClick={handleShare}>
      <Icon network={network} />
    </XButton>
  );
};
