import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { MEDIA_QUERIES } from './mediaQueries';
import { RedButton } from './Button';
import { useNavigate } from 'react-router';

const Wrapper = styled.div`
  text-align: center;
  box-sizing: border-box;
  margin-block-start: 30px;

  ${MEDIA_QUERIES.smallTablet(css`
    padding-inline: 25px;
    margin-inline-start: 15px;
  `)}

  ${MEDIA_QUERIES.mobile(css``)}
`;

const Title = styled.div`
  font-size: 35px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  margin-block-end: 14px;
  color: var(--black);

  ${MEDIA_QUERIES.smallTablet(css`
    font-size: 40px;
    margin-block-end: 8px;
  `)}

  ${MEDIA_QUERIES.mobile(css`
    font-size: 32px;
  `)}
`;

const Button = styled(RedButton)`
  max-inline-size: 150px;
`;

export const SignupCta: React.FC = () => {
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(`/signup`);
  }, [navigate]);
  return (
    <Wrapper>
      <Title>Sign up for daily reminders</Title>
      <Button onClick={() => handleClick()}>Sign up now</Button>
    </Wrapper>
  );
};
