import React, { useCallback, useMemo, useState } from 'react';
import { firstStepSignupApi } from '../../api/authApi';
import { useRecoilState } from 'recoil';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { authAtom } from '../../atom/authAtom';
import { useNotAuth } from './useAuth';
import { validatePhone } from '../../util/dataValidation';
import styled from 'styled-components';
import { FormStyle, SubmitButton } from '../callerForm/CellFormStyles';
import { PhoneCodeValidation } from './PhoneCodeValidation';
import { Input } from '../common/Input';

const SignupStyle = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  font-family: var(--font-secondary);
  margin: auto;
`;

const AgreeToTosDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin: 10px auto;
`;

const Form = styled(FormStyle)`
  max-width: 200px;
  margin: auto;
`;

export const Title = styled.h2`
  text-align: center;
`;

const Description = styled.div`
  margin: 10px;
  text-align: center;
`;

const TOSLink = styled.a`
  color: #000;
`;

interface SignupFormState {
  username: string;
  phone: string;
  zip: string;
  email: string;
  agree: boolean;
}

export const SignupForm = () => {
  const [isVerifyingAccount, setIsVerifyingAccount] = useState(false);
  const [form, setForm] = useState<SignupFormState>({
    username: '',
    phone: '',
    zip: '',
    email: '',
    agree: false,
  });

  useNotAuth();

  const isFormValid = useMemo(() => {
    return (
      form.username.length > 0 &&
      validatePhone(form.phone) &&
      form.zip.length > 0 &&
      form.agree
    );
  }, [form]);

  const [{ isLoading }, setAuth] = useRecoilState(authAtom);

  const handleSignUp = useCallback(async () => {
    await firstStepSignupApi(
      form.phone,
      form.zip,
      form.username,
      form.email,
      setAuth,
    );
    setIsVerifyingAccount(true);
  }, [setAuth, form, setIsVerifyingAccount]);

  const signupForm = useMemo(
    () => (
      <>
        <Title>Sign up</Title>
        <Description>
          Join the pledge to receive reminders and ensure that the hostages
          remain a top priority for your representatives.
        </Description>
        <Form className="flex">
          <Input
            placeholder="Phone number"
            light={true}
            onChange={(e) => setForm({ ...form, phone: e.target.value })}
          />
          <Input
            placeholder="Full name"
            light={true}
            onChange={(e) => setForm({ ...form, username: e.target.value })}
          />
          <Input
            placeholder="Zip code"
            light={true}
            onChange={(e) => setForm({ ...form, zip: e.target.value })}
          />
          <Input
            placeholder="Email (optional)"
            light={true}
            onChange={(e) => setForm({ ...form, email: e.target.value })}
          />
        </Form>
        <AgreeToTosDiv>
          <input
            type="checkbox"
            onChange={(e) =>
              setForm((form) => ({ ...form, agree: e.target.checked }))
            }
          />
          <span>
            I agree to the 1 Min a Day{' '}
            <TOSLink href="/tos" target="_blank">
              terms of service
            </TOSLink>
            .
          </span>
        </AgreeToTosDiv>
        <SubmitButton disabled={!isFormValid} onClick={handleSignUp}>
          {isLoading ? (
            <AiOutlineLoading3Quarters className="spinner" />
          ) : (
            'Submit'
          )}
        </SubmitButton>
      </>
    ),
    [form, isLoading, handleSignUp, isFormValid],
  );

  return (
    <SignupStyle>
      {isVerifyingAccount ? (
        <PhoneCodeValidation phoneNumber={form.phone} />
      ) : (
        signupForm
      )}
    </SignupStyle>
  );
};
