import React, { memo, useEffect } from 'react';
import { Outlet } from 'react-router';
import { Header } from './Header';
import { SearchZipCodeModal } from '../searchModal/SearchZipCodeModal';
import { useRecoilValue } from 'recoil';
import { zipCodeModalAtom } from '../../atom/zipCodeModalAtom';
import { useLocation } from 'react-router-dom';
import { Footer } from './Footer';
import { CallerFormModal } from '../callerForm/CallerFormModal';
import { useTryAuth } from '../auth/useAuth';
import { useShowCallYourReps } from '../common/useShowCallYourReps';
import styled from 'styled-components';

const OutletContainer = styled.div`
  margin: 20px 0;
  width: 100%;
`;

const usePageViews = () => {
  let location = useLocation();

  useEffect(() => {
    // When location changes, push a new event to dataLayer
    // @ts-ignore
    window.dataLayer.push({
      event: 'pageview',
      page: location.pathname + location.search,
    });
  }, [location]);
};

export const PageContainer = memo(function _CallerAppPage() {
  const { showZipModal } = useRecoilValue(zipCodeModalAtom);

  useShowCallYourReps();
  useTryAuth();
  usePageViews();

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      body.style.overflow = showZipModal ? 'hidden' : 'auto';
    }
  }, [showZipModal]);

  return (
    <>
      <SearchZipCodeModal />
      <CallerFormModal />
      <Header />
      <OutletContainer>
        <Outlet />
      </OutletContainer>
      <Footer />
    </>
  );
});
