import { css, keyframes } from 'styled-components';
import { Keyframes } from 'styled-components/dist/types';

const Fade = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const GrowBottom = keyframes`
  from {
    max-height: 0;
  }

  to {
    max-height: 10000vh;
  }
`;

export const animation =
  (type: Keyframes, defaultDuration: number) =>
  (duration = defaultDuration, delay = 0) => {
    return css`
      animation: ${type} ${duration}s ${delay}s ease-in-out both;
    `;
  };

export const FadeAnimation = animation(Fade, 0.2);

export const GrowBottomAnimation = animation(GrowBottom, 3);
