import styled from 'styled-components';

export const Input = styled.input<{
  hasError?: boolean;
  light?: boolean;
  width?: string;
}>`
  padding: 10px 16px;
  color: var(--white);
  color: ${({ light }) => (light ? 'black' : 'white')};
  background-color: ${({ light }) => (light ? 'auto' : 'transparent')};
  border: 1px solid
    ${({ hasError, light }) =>
      hasError ? 'var(--red)' : light ? 'black' : 'white'};
  font-size: 14px;
  font-family: var(--font-secondary);
  word-wrap: break-word;
  margin-bottom: 12px;
  border-radius: 4px;
  box-sizing: content-box;
  margin: 1px 1px 7px;
  width: ${({ width }) => width ?? '-webkit-fill-available'};

  &::placeholder {
    color: ${({ light }) => (light ? 'auto' : 'white')};
    opacity: ${({ light }) => (light ? 'auto' : '.6')};
  }

  &:focus {
    outline: none;
    border-width: 2px;
    margin: 0 0 6px;
  }
`;

export const Select = styled.select`
  background-color: transparent;
  width: 100%;
  color: #fff;
  font-family: var(--font-secondary);
  font-size: 18px;
  padding: 10px 0;
  border: 1px solid var(--light-gray);
  border-radius: 4px;
  margin-top: 10px;

  &:hover {
    background: var(--light-gray);
    color: black;
  }
`;
