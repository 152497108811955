import { apiInstance } from './apiInstance';

export interface Contact {
  name?: string;
  email?: string;
  phone?: string;
  recurring?: boolean;
  filter: string;
  firstRepId: string;
}

export interface Call {
  rep_id?: string;
  confirmed?: boolean;
}

export const createContact = async (payload: Contact) => {
  return await apiInstance.post('contacts', payload);
};

export const addCall = async (payload: Call) => {
  return await apiInstance.post('calls', payload);
};
