import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DashboardBoxStyle } from '../leaderboard/LeaderboardStyle';
import { deleteReminder, getReminders } from '../../../api/dashboardApi';
import styled from 'styled-components';
import { SCALE_2 } from '../../common/theme';
import { DashboardStyle } from '../Dashboard';
import Skeleton from 'react-loading-skeleton';
import {
  ReminderModalType,
  useReminderForm,
} from '../../reminder/useReminderForm';
import { Button } from '../../common/Button';
import { dateToWords } from '../../../util/dates';
import { useForceAuth } from '../../auth/useAuth';
import { AiFillPlusCircle } from 'react-icons/ai';

const ReminderRow = styled.div`
  display: flex;
  justify-content: space-around;
  padding: ${SCALE_2} 0;
  line-height: 30px;
  gap: 10px;

  &:nth-child(odd) {
    background-color: #f2f2f2;
  }
`;

const ReminderRowHeader = styled(ReminderRow)`
  font-weight: bold;
  text-align: left;
  grid-row: 1;
`;

const ReminderRowCell = styled.div`
  width: 30%;
`;

const CancelButtonStyle = styled(Button)`
  font-size: 18px;
  box-shadow: unset;
  padding: unset;
`;

const AddReminderButton = styled(Button)`
  width: fit-content;
  box-shadow: unset;
  font-size: 20px;
  padding: 6px 14px;
`;

const TitleStyle = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
`;

const EmptyRemindersList = styled.div`
  font-size: 22px;
  margin: 20px auto;
  text-align: center;
  cursor: pointer;
  background: var(--red);
  width: fit-content;
  padding: 6px 12px;
  border-radius: 8px;
  color: white;
`;

export interface Reminder {
  reminderDate: Date;
  _id: string;
}

export const RemindersPage = () => {
  const [reminders, setReminders] = useState<Reminder[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  useForceAuth();

  const updateReminders = useCallback(async () => {
    setIsLoading(true);
    setReminders(await getReminders());
    setIsLoading(false);
    setIsLoading(false);
  }, [setReminders, setIsLoading]);

  useEffect(() => {
    updateReminders();
  }, [updateReminders, setIsLoading]);

  const { reminderModal, setModalType } = useReminderForm(updateReminders);

  const handleDeleteReminder = useCallback(
    (id: string) => {
      deleteReminder(id);
      setReminders((reminders) =>
        [...reminders].filter(({ _id }) => _id !== id),
      );
    },
    [setReminders],
  );

  const handleReminderForm = useCallback(() => {
    setModalType(ReminderModalType.FORM);
  }, [setModalType]);

  const remindersList = useMemo(() => {
    if (!reminders.length) {
      return (
        <EmptyRemindersList onClick={handleReminderForm}>
          Add a reminder
        </EmptyRemindersList>
      );
    }

    return reminders.map(({ reminderDate, _id }, index) => (
      <ReminderRow>
        <ReminderRowCell key={index}>
          {dateToWords(reminderDate)}
        </ReminderRowCell>
        <ReminderRowCell>By phone</ReminderRowCell>
        <ReminderRowCell>
          <CancelButtonStyle onClick={() => handleDeleteReminder(_id)}>
            Cancel
          </CancelButtonStyle>
        </ReminderRowCell>
      </ReminderRow>
    ));
  }, [reminders, handleDeleteReminder, handleReminderForm]);

  const remindersItems = useMemo(() => {
    if (isLoading) {
      return (
        <>
          <Skeleton count={3} />
          <Skeleton count={2} width={500} />
          <Skeleton count={4} />
        </>
      );
    }

    return (
      <>
        {!!reminders.length && (
          <ReminderRowHeader>
            <ReminderRowCell>Time</ReminderRowCell>
            <ReminderRowCell>Device</ReminderRowCell>
            <ReminderRowCell></ReminderRowCell>
          </ReminderRowHeader>
        )}
        {remindersList}
      </>
    );
  }, [remindersList, isLoading, reminders]);

  return (
    <DashboardStyle>
      {reminderModal}
      <DashboardBoxStyle>
        <TitleStyle>
          Reminders
          <AddReminderButton onClick={handleReminderForm}>
            <AiFillPlusCircle />
          </AddReminderButton>
        </TitleStyle>
        {remindersItems}
      </DashboardBoxStyle>
    </DashboardStyle>
  );
};
