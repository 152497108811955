import styled, { css } from 'styled-components';
import { Social } from '../common/Social';
import { MEDIA_QUERIES, MobileBR } from '../common/mediaQueries';
import { useNavigate } from 'react-router';
import { useCallback } from 'react';

const FooterWrapper = styled.footer`
  padding: 170px 100px 50px 100px;
  background-image: url('/footerbg.png');
  background-size: 100% 100%;
  color: var(--white);
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-template-rows: auto auto 1fr;
  row-gap: 20px;
  column-gap: 40px;
  inline-size: 100vw;

  ${MEDIA_QUERIES.mobile(css`
    padding: 250px 10px 10px 40px;
    background-size: 300% 100%;
    grid-template-columns: auto;
    grid-template-rows: auto;
  `)}
`;

const FooterNames = styled.img.attrs({
  src: '/footerNames.gif',
  alt: '',
})`
  grid-column: 1;
  grid-row: 1;
  block-size: 130px;
  transform: translateX(-15%);
  margin: 0;

  ${MEDIA_QUERIES.mobile(css`
    grid-column: 1;
    grid-row: 1;
  `)}
`;

const TextCenter = styled.div`
  font-size: 12px;
  font-family: var(--font-secondary);
  font-weight: 400;
  line-height: 1.6;

  grid-column: 2;
  grid-row: 1;
  max-inline-size: 33vw;

  ${MEDIA_QUERIES.tablet(css`
    grid-row: unset;
    grid-column: 1 / -1;
    max-inline-size: 70vw;
  `)}

  ${MEDIA_QUERIES.mobile(css`
    grid-column: 1/2;
    grid-row: 2;
  `)}
`;

const TextRight = styled.div`
  font-size: 12px;
  font-family: var(--font-secondary);
  font-weight: 400;
  line-height: 1.6;
  grid-column: 3;
  grid-row: 1;

  ${MEDIA_QUERIES.tablet(css`
    grid-row: unset;
    grid-column: 1 / -1;
    max-inline-size: 70vw;
  `)}

  ${MEDIA_QUERIES.mobile(css`
    grid-column: 1/2;
    grid-row: 3;
  `)}
`;

const TextBreak = styled.p`
  inline-size: 100%;
  display: block;
  margin-block-end: 10px;
`;

const TextLink = styled.a`
  color: #fff;
  text-decoration: underline;
`;

const StyledSocial = styled(Social)`
  align-self: start;
  grid-column: 1;
  grid-row: 2;
  margin-block-end: 16px;

  ${MEDIA_QUERIES.mobile(css`
    grid-column: 1/2;
    grid-row: 5;
  `)}
`;

const TOSLink = styled.a`
  color: white;
  font-size: 13px;
  font-family: var(--font-secondary);
  white-space: nowrap;
  cursor: pointer;
  text-decoration: underline;
  align-self: start;
  grid-column: 2;
  grid-row: 2;

  ${MEDIA_QUERIES.mobile(css`
    margin: 10px 0;
    grid-column: 1/2;
    grid-row: 4;
  `)}
`;

const PrivacyLink = styled.a`
  color: white;
  font-size: 13px;
  font-family: var(--font-secondary);
  white-space: nowrap;
  cursor: pointer;
  text-decoration: underline;
  align-self: start;
  margin-inline-start: 120px;
  grid-column: 2;
  grid-row: 2;

  ${MEDIA_QUERIES.mobile(css`
    margin: 10px 120px;
    grid-column: 1/2;
    grid-row: 4;
  `)}
`;

const Copyright = styled.span`
  font-size: 12px;
  text-align: start;
  font-family: var(--font-secondary);
  font-weight: 400;
  grid-column: 2;
  grid-row: 3;

  ${MEDIA_QUERIES.mobile(css`
    grid-column: 1/2;
    grid-row: 6;
  `)}
`;

const HashtagsWrapper = styled.span`
  grid-column: 3;
  grid-row: 2;
  position: relative;
  font-family: var(--font-main);

  ${MEDIA_QUERIES.mobile(css`
    grid-column: 1;
    grid-row: 1;
    margin-inline-start: 170px;
    font-size: 18px;
  `)}
`;

const Hashtag = styled.span`
  color: var(--red);
  margin-inline-end: 15px;
`;

export const Footer = () => {
  const navigate = useNavigate();
  const handledTOSClick = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(`/tos`);
  }, [navigate]);
  const handledPrivacyClick = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(`/privacy`);
  }, [navigate]);

  return (
    <FooterWrapper>
      <FooterNames />
      <StyledSocial />
      <TextCenter>
        This website, oneminaday.com, was created to generate significant
        pressure on US elected officials to demand the release of the hostages
        in Gaza by any mean necessary.
        <TextBreak></TextBreak>
        The website simplifies the process of contacting your elected
        representative by inserting your zipcode and making phone calls and
        emailing your congressperson and senators.
        <TextBreak></TextBreak>
      </TextCenter>
      <TOSLink onClick={handledTOSClick}>Terms of Service</TOSLink>
      <PrivacyLink onClick={handledPrivacyClick}>Privacy Policy</PrivacyLink>
      <Copyright>
        Copyright&nbsp;©️&nbsp;2023.&nbsp;One&nbsp;Min&nbsp;A&nbsp;Day.&nbsp;All&nbsp;Rights&nbsp;Reserved.
      </Copyright>
      <TextRight>
        Together, we will raise our voices and force the US government to ensure
        the safety of all the hostages and their swift release.
        <TextBreak></TextBreak>
        For more information about the hostages visit the{' '}
        <TextLink target="_blank" href="https://stories.bringthemhomenow.net">
          Hostages and Missing Families Forum
        </TextLink>
        .<TextBreak></TextBreak>
        Join the New York efforts and activities at{' '}
        <TextLink target="_blank" href="https://linktr.ee/BringThemHomeNow_NY">
          Bring Them Home Now
        </TextLink>
      </TextRight>
      <HashtagsWrapper>
        <Hashtag>#BRINGTHEMHOMENOW</Hashtag>
        <MobileBR></MobileBR>
        <Hashtag>#1MINADAY</Hashtag>
        <MobileBR></MobileBR>
        <Hashtag>#NoHostageLeftBehind</Hashtag>
      </HashtagsWrapper>
    </FooterWrapper>
  );
};
