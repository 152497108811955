import React, { useState } from 'react';
import {
  Autocomplete,
  Libraries,
  useJsApiLoader,
} from '@react-google-maps/api';
import { MEDIA_QUERIES } from '../common/mediaQueries';
import styled, { css } from 'styled-components';

interface GoogleMapsAutoCompleteProps {
  onPlaceSelected: (zipCode: string) => void;
  inputOverride?: JSX.Element;
}

const libraries: Libraries = ['places'];

const Input = styled.input.attrs({
  type: 'text',
  placeholder: 'Find by address',
  autoComplete: 'off',
})`
  border: 0;
  background-color: transparent;
  height: 60px;
  width: 100%;
  color: #fff;
  font-family: var(--font-secondary);
  font-size: 18px;

  &::placeholder {
    color: var(--white);
    opacity: 0.6;
  }

  ${MEDIA_QUERIES.tablet(css`
    font-size: 16px;
  `)}

  ${MEDIA_QUERIES.mobile(css`
    font-size: 14px;
  `)}
`;

const StyleAutoComplete = styled(Autocomplete)`
  color: #bf4f74;
  font-weight: bold;
  width: 100%;
`;

const GoogleMapsAutoComplete: React.FC<GoogleMapsAutoCompleteProps> = ({
  onPlaceSelected,
}: GoogleMapsAutoCompleteProps) => {
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);

  const onLoad = (autoC: google.maps.places.Autocomplete) => {
    setAutocomplete(autoC);
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-autocomplete-script',
    googleMapsApiKey: 'AIzaSyDirNlh8THfYCjrMj7qtxUKY4WNhP59KVk',
    libraries: libraries,
  });

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      let zipCode = '';

      for (const component of place.address_components || []) {
        if (component.types[0] === 'postal_code') {
          zipCode = component.long_name;
          break;
        }
      }

      onPlaceSelected(zipCode);
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };

  return (
    <>
      {isLoaded && (
        <StyleAutoComplete
          onLoad={onLoad}
          onPlaceChanged={onPlaceChanged}
          className=""
        >
          <Input />
        </StyleAutoComplete>
      )}
    </>
  );
};

export default GoogleMapsAutoComplete;
