import styled from 'styled-components';
import { BlackTextButton, RedButtonCallPage } from '../common/Button';
import { CheckIcon } from './CheckIcon';
import { ShareToSocial } from '../common/Share';

const ActionsWrapper = styled.div<{ row?: boolean }>`
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  gap: 20px;
  border-radius: 20px;
  z-index: 10;
  background-image: linear-gradient(to top, var(--white) 70%, transparent);
  padding: 40px 0 20px;
  width: 100vw;
  justify-content: center;
`;

const ShareWrapper = styled.div`
  text-align: center;
  font-size: 20px;
  line-height: 30px;
`;

interface CallPageActionsProps {
  onCalled: () => void;
  row?: boolean;
  onRemind: () => void;
}

export const CallPageActions: React.FC<CallPageActionsProps> = ({
  onCalled,
  onRemind,
  row,
}) => {
  const url = 'https://www.oneminaday.com';
  const sharedText = 'I called my reps today! Got a minute to call them to?';

  return (
    <>
      <ActionsWrapper row={row}>
        <RedButtonCallPage className={'i-called-btn'} onClick={onCalled}>
          <CheckIcon />I Called or left a voicemail!
        </RedButtonCallPage>
        <BlackTextButton className={'remind-me-btn'} onClick={onRemind}>
          Remind me later
        </BlackTextButton>
      </ActionsWrapper>
      <ShareWrapper>
        URGE YOUR FRIENDS TO CALL AS WELL
        <br></br>
        <ShareToSocial network="FB" text={sharedText} url={url} />
        &nbsp;&nbsp;
        <ShareToSocial
          network="X"
          text={sharedText}
          url={url}
          hashtag={'1MINADAY'}
        />
        &nbsp;&nbsp;
        <ShareToSocial
          network="LI"
          text={sharedText + ' ' + url + ' #1MINADAY'}
          url={url}
        />
      </ShareWrapper>
    </>
  );
};
