import React, { useState } from 'react';
import styled from 'styled-components';
import { SCALE_4 } from '../common/theme';
import { useRecoilValue } from 'recoil';
import { authAtom } from '../../atom/authAtom';
import { Link } from 'react-router-dom';
import { formatPhone } from '../callPage/utils';

const avatarCarrot = (
  <>
    <circle cx="7" cy="7" r="7" fill="#F5F5F5" />
    <circle cx="7" cy="7" r="6.5" stroke="white" strokeOpacity="0.75" />
    <path
      d="M9.49153 5F.50227L7.19853 7.78466L4.91614 5.49166L4.20914 6.19539L7.19526 9.19539L10.1953 6.20927L9.49153 5.50227Z"
      fill="#999999"
    />
  </>
);

const AvatarStyle = styled.div`
  height: 50px;
  width: 50px;
  background: white;
  color: black;
  border-radius: 100%;
  margin-bottom: 10px;
  line-height: 50px;
  font-size: 20px;
  text-align: center;
`;

const PhoneNumberStyle = styled.div`
  margin-top: 10px;
  font-size: 16px;
`;

const MenuCircle = styled.div`
  height: 50px;
  width: 50px;
  background: black;
  color: white;
  border-radius: 100%;
  position: relative;
  cursor: pointer;
  font-family: var(--font-secondary);
  z-index: 10;

  span {
    display: block;
    line-height: 50px;
    font-size: 20px;
    text-align: center;
  }
`;

const Carrot = styled.svg`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const MenuStyle = styled.div<{ show: boolean }>`
  width: 200px;
  position: absolute;
  color: white;
  background: black;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: all 0.2s ease;
  border-radius: 14px;
  line-height: 22px;
  left: ${(props) => (props.show ? '-150px' : '-145px')};
  top: ${(props) => (props.show ? '60px' : '55px')};
  overflow: hidden;
  cursor: auto;
  font-family: var(--font-secondary);
`;

const DisplayNameStyle = styled.div`
  margin: 10px 0 20px 0;
  padding: ${SCALE_4};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LinksStyle = styled.div`
  background: var(--gray);
  display: flex;
  flex-direction: column;
`;

const MenuItem = styled(Link)`
  text-decoration: unset;
  color: white;
  padding: 20px 0;
  font-size: 20px;
  text-align: center;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;

  &:not(:last-child) {
    border-bottom: 1px solid var(--light-gray);
  }

  &:hover:last-child {
    border-left: 4px solid transparent;
  }

  &:last-child {
    font-size: 13px;
    padding: 20px 10px;
  }

  &:hover {
    background: var(--light-gray);
    border-left: 4px solid red;
  }
`;

const CallMenuItem = styled(MenuItem)`
  background: var(--red);

  &:hover {
    color: var(--red);
    background: white;
  }
`;

export const SiteMenu = () => {
  const [showMenu, setShowMenu] = useState(false);
  const {
    userDetails: { displayName, phoneNumber },
  } = useRecoilValue(authAtom);

  const userNameFirstChar = displayName.charAt(0);

  return (
    <MenuCircle
      onMouseEnter={() => setShowMenu(true)}
      onMouseLeave={() => setShowMenu(false)}
    >
      <span>{userNameFirstChar}</span>
      <Carrot
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        {avatarCarrot}
      </Carrot>
      <MenuStyle show={showMenu}>
        <DisplayNameStyle>
          <AvatarStyle>{userNameFirstChar}</AvatarStyle>
          <div>{displayName}</div>
          <PhoneNumberStyle>{formatPhone(phoneNumber)}</PhoneNumberStyle>
        </DisplayNameStyle>
        <LinksStyle>
          <CallMenuItem to="?show-zip-form">Make a call</CallMenuItem>
          <MenuItem to="/dashboard">Reminders</MenuItem>
          <MenuItem to="/dashboard/settings">Settings</MenuItem>
          <MenuItem to="/logout">Logout</MenuItem>
        </LinksStyle>
      </MenuStyle>
    </MenuCircle>
  );
};
