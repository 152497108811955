import moment from 'moment';

export const dateToWords = (date: Date) => {
  const momentDate = moment(date).endOf('day');
  const todayMoment = moment().endOf('days');
  const time = moment(date).format(' hA');
  const isToday = momentDate.isSame(todayMoment, 'day');
  const isTomorrow = momentDate.diff(todayMoment, 'days') === 1;
  if (isToday) {
    return 'Today, ' + time;
  }

  if (isTomorrow) return 'Tomorrow, ' + time;

  return momentDate.format('MM/DD') + ', ' + time;
};
