import styled, { css } from 'styled-components';
import { FadeAnimation } from '../common/animations';
import { MEDIA_QUERIES } from '../common/mediaQueries';

const VideoWrapper = styled.div`
  position: relative;
  display: contents;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: 0 20px 10px 20px;
  ${FadeAnimation()}

  ${MEDIA_QUERIES.smallTablet(css`
    margin: 0 10px;
  `)}

  ${MEDIA_QUERIES.mobile(css`
    gap: 8px;
  `)}
`;

const VideoComponent = styled.iframe.attrs({
  title: '1 Min a Day',
  frameborder: '0',
  src: 'https://www.youtube.com/embed/TGhey2ZPmTo?si=Wkd801H8KKdZ1wik&autoplay=0&rel=0&showinfo=0&loop=0&fs=0',
})`
  max-height: calc(100vh - var(--header-height) - 80px);
  aspect-ratio: 1.7777777778;
  width: 100%;

  object-fit: cover;
  border-radius: px;
  overflow: hidden;
`;

export const Video = () => (
  <VideoWrapper>
    <VideoComponent />
  </VideoWrapper>
);
