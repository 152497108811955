import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { MEDIA_QUERIES } from './components/common/mediaQueries';
import { router } from './components/app/Router';
import { RouterProvider } from 'react-router';
import { RecoilRoot } from 'recoil';
import 'react-loading-skeleton/dist/skeleton.css';
import LogRocket from 'logrocket';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  block-size: 100vh;

  --header-height: 175px;

  ${MEDIA_QUERIES.laptop(css`
    --header-height: 150px;
  `)}

  ${MEDIA_QUERIES.tablet(css`
    --header-height: 130px;
  `)}

  ${MEDIA_QUERIES.smallTablet(css`
    --header-height: 110px;
  `)}

  ${MEDIA_QUERIES.mobile(css`
    --header-height: 90px;
  `)}
`;

const App = () => {
  LogRocket.init('tvcuci/one-min-a-day');
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    //document.fonts.onloadingdone = () =>
    setFontsLoaded(true);
  }, [setFontsLoaded]);

  return (
    <RecoilRoot>
      <PageWrapper style={{ opacity: fontsLoaded ? 1 : 0 }}>
        <RouterProvider router={router} />
      </PageWrapper>
    </RecoilRoot>
  );
};
export default App;
