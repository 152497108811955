import styled, { css } from 'styled-components';
import React, { useState } from 'react';
import { Timer } from './Timer';
import { useNavigate } from 'react-router';
import { MEDIA_QUERIES } from '../common/mediaQueries';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authAtom } from '../../atom/authAtom';
import { SiteMenu } from './SiteMenu';
import { useCheckMobileScreen } from '../../hooks/useCheckScreenSize';

const HeaderWrapper = styled.header`
  width: 100%;
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  color: var(--color-gray);
  background-color: var(--white);
  z-index: 50;
  gap: 20px;
  margin-top: 10px;

  ${MEDIA_QUERIES.smallTablet(css`
    padding-inline: 25px;
  `)}
`;

export const AppLogoWrapper = styled.div`
  width: 240px;
  display: flex;
  justify-content: start;
  margin-left: 10px;
`;

export const DefaultAppLogoStyle = styled.img.attrs({
  alt: '',
  src: '/CallsLogo.png',
})`
  height: 80px;
  cursor: pointer;
  align-items: center;

  ${MEDIA_QUERIES.smallTablet(css`
    float: left;
    margin: 0;
  `)}
`;

export const HamburgerIcon = styled.img.attrs({
  alt: '',
  src: '/hamburger.png',
})`
  height: 25px;
  cursor: pointer;
  align-items: center;

  ${MEDIA_QUERIES.smallTablet(css`
    float: left;
    margin: 0;
  `)}
`;

const TimerWrapper = styled.div`
  width: 200px;

  ${MEDIA_QUERIES.smallTablet(css`
    position: relative;
    right: 10px;
    min-width: 200px;
  `)}
`;

const MobileBR = styled.br`
  display: none;
  ${MEDIA_QUERIES.smallTablet(css`
    display: block;
  `)}
`;

const MenuItem = styled(Link)`
  text-decoration: unset;
  padding: 6px 8px;
  line-height: 38px;
  border-bottom: 2px solid transparent;

  &:hover {
    border-bottom: 2px solid var(--red);
  }

  ${MEDIA_QUERIES.mobile(css`
    &:hover {
      border-bottom: 0;
    }
  `)}x
`;

const MenuContainer = styled.div`
  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  width: 240px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
  animation: slideInFromRight 0.5s ease-out forwards;

  ${MEDIA_QUERIES.mobile(css`
    margin: 3px 8px unset unset;
    background: var(--light-gray);
    position: absolute;
    border-radius: 8px;
    right: 0;
    top: 50px;
    height: 50px;
  `)}
`;

const VolunteerLink = styled(MenuItem)`
  color: var(--red);
  transition: all 0.1s ease-in;
  width: auto;
`;

const SignUpLink = styled(MenuItem)`
  color: var(--black);
`;

const MenuButton = styled.button`
  position: absolute;
  opacity: 50%;
  border: none;
  color: white;
  /* padding: 5px 10px; */
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  border-radius: 8px;
  right: 20px;
  top: 20px;
  width: 20px;
`;

export const Header = () => {
  const navigate = useNavigate();
  const isMobileScreen = useCheckMobileScreen();
  const { isAuth } = useRecoilValue(authAtom);
  const [isMenuShowing, setMenuShowing] = useState(false);

  const toggleMenu = () => {
    setMenuShowing(!isMenuShowing);
  };

  return (
    <HeaderWrapper>
      <AppLogoWrapper>
        <DefaultAppLogoStyle onClick={() => navigate('/')} />
      </AppLogoWrapper>
      <TimerWrapper>
        <Timer />
      </TimerWrapper>
      {isMobileScreen ? (
        <MenuButton onClick={toggleMenu}>
          <HamburgerIcon />
        </MenuButton>
      ) : (
        <></>
      )}
      {isMenuShowing || !isMobileScreen ? (
        <MenuContainer>
          <VolunteerLink
            to={isMobileScreen ? '/volunteer' : '/?showVolunteerForm=true'}
          >
            Volunteers needed
          </VolunteerLink>
          {isAuth ? (
            <SiteMenu />
          ) : (
            <>
              <SignUpLink to="/signup">Sign up</SignUpLink>{' '}
              <SignUpLink to="/login">Log in</SignUpLink>
            </>
          )}
        </MenuContainer>
      ) : (
        <></>
      )}

      <MobileBR />
    </HeaderWrapper>
  );
};
