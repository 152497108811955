import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { repsAtom } from '../../atom/repsAtom';
import { useParams } from 'react-router';
import { authAtom } from '../../atom/authAtom';
import { ReminderForm } from './ReminderForm';
import { HeartIcon } from '../common/HeartIcon';
import { Social } from '../common/Social';
import { Modal } from '../common/Modal';
import styled, { css } from 'styled-components';
import { MEDIA_QUERIES } from '../common/mediaQueries';
import { PhoneCodeValidation } from '../auth/PhoneCodeValidation';

const Title = styled.div`
  font-size: 48px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 24px;

  ${MEDIA_QUERIES.smallTablet(css`
    font-size: 40px;
    margin-bottom: 18px;
  `)}

  ${MEDIA_QUERIES.mobile(css`
    font-size: 32px;
  `)}
`;

export enum ReminderModalType {
  THANK_YOU = 'thankYou',
  FORM = 'form',
}

const Subtitle = styled.div`
  font-family: var(--font-secondary);
  text-align: center;
  margin-bottom: 60px;
  line-height: 1.4;

  ${MEDIA_QUERIES.smallTablet(css`
    margin-bottom: 40px;
  `)}

  ${MEDIA_QUERIES.mobile(css`
    font-size: 14px;
    margin-bottom: 30px;
  `)}
`;

const SuccessMessage = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--black);
  font-family: var(--font-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

const BottomText = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  margin-bottom: 20px;
  font-size: 24px;
  margin-top: 16px;
`;

const VerifyPhoneNumberWrapper = styled.div`
  background: white;
  padding: 20px;
  border-radius: 20px;
  color: black;
  border: 1px solid var(--red);

  input {
    width: -webkit-fill-available;
  }
`;

const ThankYouFormOrVerify = ({
  isAuth,
  phoneNumber,
}: {
  isAuth: boolean;
  phoneNumber: string | null;
}) => {
  return (
    <>
      <SuccessMessage>Thank you for setting up a reminder!</SuccessMessage>
      {!isAuth && phoneNumber && (
        <VerifyPhoneNumberWrapper>
          <PhoneCodeValidation phoneNumber={phoneNumber} />
        </VerifyPhoneNumberWrapper>
      )}
    </>
  );
};

export const useReminderForm = (handleClose?: () => void) => {
  const [modalType, setModalType] = useState<ReminderModalType | null>(null);
  const { repsFilter } = useRecoilValue(repsAtom);
  const { id } = useParams();
  const { isAuth } = useRecoilValue(authAtom);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);

  const title =
    modalType === ReminderModalType.THANK_YOU ? 'Thank you' : 'Remind me';

  const subTitle =
    modalType === ReminderModalType.THANK_YOU ? (
      <ThankYouFormOrVerify isAuth={isAuth} phoneNumber={phoneNumber} />
    ) : (
      <>
        See you again tomorrow! <br />
        Together, we will save their lives!
      </>
    );

  const isOpen = !!modalType;

  const close = () => {
    if (handleClose) {
      handleClose();
    }
    setModalType(null);
  };

  const reminderModal = (
    <Modal open={isOpen} close={close} showCloseButton withPadding isCentered>
      <Title>{title}</Title>
      <Subtitle>{subTitle}</Subtitle>
      <ReminderForm
        isOpen={isOpen}
        filter={repsFilter}
        firstRepId={id!}
        onSuccess={(phoneNumber: string) => {
          setPhoneNumber(phoneNumber);
          setModalType(ReminderModalType.THANK_YOU);
        }}
      />
      <BottomText>
        thank you!
        <HeartIcon />
      </BottomText>
      <Social />
    </Modal>
  );

  return { reminderModal, setModalType };
};
