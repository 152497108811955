import { apiInstance } from './apiInstance';
import { LeaderboardRecord } from 'oneminuteaday-common';
import { Reminder } from '../components/dashboard/reminder/RemindersPage';

export const getLeaderboard = async () => {
  const { data } =
    await apiInstance.get<LeaderboardRecord[]>('calls/leaderboard');
  return data;
};

export const getReminders = async () => {
  const { data } = await apiInstance.get<Reminder[]>('reminder');
  return data;
};

export const deleteReminder = async (id: string) =>
  await apiInstance.get(`reminder/delete/${id}`);
