import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { MEDIA_QUERIES } from '../common/mediaQueries';

export const TimerWrapper = styled.div<{ big?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1.5em;
  row-gap: 0.5em;
  color: var(--black);
  flex-wrap: wrap;

  ${({ big }) => css`
    font-size: ${big ? '40px' : '12px'};

    ${MEDIA_QUERIES.laptop(css`
      font-size: ${big ? `35px` : '12px'};
    `)};

    ${MEDIA_QUERIES.tablet(css`
      font-size: ${big ? `28px` : '12px'};
    `)};

    ${MEDIA_QUERIES.smallTablet(css`
      font-size: ${big ? `28px` : '9px'};
    `)};

    ${MEDIA_QUERIES.mobile(css`
      font-size: ${big ? `24px` : '9px'};
    `)};

    ${MEDIA_QUERIES.smallMobile(css`
      font-size: ${big ? `20px` : '9px'};
    `)};
  `};
`;

const UnitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
`;

const TimeValue = styled.span`
  font-size: 2em;
  font-weight: 700;
`;

const TimeUnit = styled.span`
  font-size: 1.2em;
  text-transform: uppercase;
`;

const Text = styled.span<{ big?: boolean }>`
  flex-basis: 100%;
  font-weight: 700;
  text-align: center;
  color: var(--red); /* Set the text color to red */

  ${({ big }) => css`
    font-size: ${big ? '0.8em' : '1.2em'};
    padding: 0.5em;
    ${!big
      ? 'color: var( --white); line-height: 2.6em; display:block; max-width: 200px; background-image: url("/redSmear.png"); background-size: 100% 100%;'
      : ''}
  `}
`;

interface TimeDifference {
  days: number;
  hrs: string;
  mins: string;
  secs: string;
}

const calculateTimeLeft = (): TimeDifference => {
  const difference = +new Date() - +new Date('2023-10-06T06:30:00');
  const hrs = Math.floor((difference / (1000 * 60 * 60)) % 24).toString()
  const mins = Math.floor((difference / 1000 / 60) % 60).toString()
  const secs = Math.floor((difference / 1000) % 60).toString()

  return {
    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
    hrs: hrs.length === 2 ? hrs : '0' + hrs,
    mins: mins.length === 2 ? mins : '0' + mins,
    secs: secs.length === 2 ? secs : '0' + secs
  };
};

interface TimerProps {
  big?: boolean;
}

export const Timer: React.FC<TimerProps> = ({ big }) => {
  const [timeLeft, setTimeLeft] = useState<TimeDifference>(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <TimerWrapper big={big}>
      {Object.entries(timeLeft).map(([unit, value]) => (
        <UnitWrapper key={unit}>
          <TimeValue>{value}</TimeValue>
          <TimeUnit>{unit}</TimeUnit>
        </UnitWrapper>
      ))}
      <Text big={big}>Since being taken hostage by Hamas</Text>
    </TimerWrapper>
  );
};
