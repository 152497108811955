import styled from 'styled-components';
import { SCALE_2, SCALE_4, SCALE_5 } from '../../common/theme';

export const DashboardBoxStyle = styled.div`
  font-family: var(--font-secondary);
  display: grid;
`;

export const LeaderboardStyle = styled.div`
  background-color: var(--black);
  width: 100%;
  margin: auto;
  font-size: 20px;
  max-height: 400px;
  overflow: scroll;
`;

export const LeaderboardListStyle = styled.div`
  max-width: 400px;
  margin: 20px auto;
`;

export const LeaderboardRecordStyle = styled.div<{ self?: boolean }>`
  display: flex;
  justify-content: start;
  gap: 12px;
  margin: ${SCALE_2} ${SCALE_4};
  background: ${({ self }) => (self ? '#007813' : 'var(--red)')};
  color: var(--white);
  font-size: 18px;
  height: 30px;
  align-items: center;
  padding: 0 10px;

  &:hover {
    opacity: 0.8;
  }
`;

export const LeaderboardRankStyle = styled.div`
  text-align: center;
  width: 30px;
  margin-left: 2px;
`;

export const EmptyItemList = styled.div`
  color: grey;
  display: inline-block;
  background-repeat: no-repeat;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
    100%;
  animation: placeholderShimmer 2.5s infinite;
  height: 100%;
  width: 100%;
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  @keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
`;

export const LeaderboardDetailsStyle = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  width: 100%;
  text-align: center;
`;

export const LeaderboardSteakStyle = styled.div`
  width: 50px;
`;

export const HeaderSteakStyle = styled(LeaderboardRecordStyle)`
  justify-content: end;
  padding: 0 10px;
  background: var(--white);
  color: var(--red);
`;
