import React, { memo, useCallback } from 'react';
import styled from 'styled-components';
import { CallerFormContent } from './CallerFormContent';
import { useNavigate } from 'react-router';

const CallerFormDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
  color: white;
  background: black;
  padding: 30px;
`;

export const CallerFormPage = memo(function _CallerFormDrawer() {
  const navigate = useNavigate();
  const handleCloseCallerForm = useCallback(() => navigate('/'), [navigate]);
  return (
    <CallerFormDiv>
      <CallerFormContent onCloseForm={handleCloseCallerForm} />
    </CallerFormDiv>
  );
});
