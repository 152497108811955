export const CheckIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 8 7"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M1.5 4.125L3.31818 6C4.17375 3.54232 4.88628 2.46386 6.5 1"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
