import React from 'react';
import styled, { css } from 'styled-components';
import { MEDIA_QUERIES } from './mediaQueries';
import { PhoneIcon } from './PhoneIcon';
import { Button, FloatingRedButton } from './Button';
import { useSetRecoilState } from 'recoil';
import { zipCodeModalAtom } from '../../atom/zipCodeModalAtom';

const Wrapper = styled.div`
  color: var(--red, #d63e22);
  text-align: center;
  margin: 5px 0 20px calc(50% - 172px);
  box-sizing: border-box;
  width: 300px;

  ${MEDIA_QUERIES.smallTablet(css`
    padding-inline: 25px;
    margin-left: 15px;
  `)}
`;

const Arrow = styled.img.attrs({
  src: '/arrow.png',
  alt: '',
})`
  float: left;
  position: relative;
  left: 30px;
  top: 5px;

  ${MEDIA_QUERIES.smallTablet(css`
    margin: 0 auto;
    transform: translateX(15%);
    left: 10px;
  `)}
`;

const CTALabel = styled.span`
  font-family: Droid Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 135.139%; /* 21.622px */
  position: relative;
  top: 10px;
  min-width: 400px;

  ${MEDIA_QUERIES.smallTablet(css`
    position: relative;
    left: 18px;
  `)}
`;

interface CallYourRepsCtaProps {
  children?: React.ReactNode;
  floating?: boolean;
  isPlaying?: boolean;
  className?: string;
}

export const CallYourRepsCta: React.FC<CallYourRepsCtaProps> = ({
  children,
  floating,
  isPlaying,
  className,
}) => {
  const setZipCodeModal = useSetRecoilState(zipCodeModalAtom);
  const ButtonComponent = floating ? FloatingRedButton : Button;

  return (
    <Wrapper>
      <Arrow />
      <ButtonComponent
        onClick={() =>
          setZipCodeModal((old) => ({ ...old, showZipModal: true }))
        }
        isPlaying={isPlaying}
        className={className}
      >
        {children ?? (
          <>
            <PhoneIcon />
            Find your reps
          </>
        )}
      </ButtonComponent>
      <CTALabel>
        Speak to your reps directly or leave a voicemail. We’ll provide a
        script.
      </CTALabel>
    </Wrapper>
  );
};
