import styled, { css } from 'styled-components';
import { RepDetails } from './RepDetails';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { GrowBottomAnimation } from '../common/animations';
import { Modal } from '../common/Modal';
import { Rep, repsData } from '../../hooks/repsData';
import { SearchIcon } from '../common/SearchIcon';
import { MEDIA_QUERIES } from '../common/mediaQueries';
import { List } from 'react-virtualized';
import GoogleMapsAutoComplete from './AddressAutoComplete';
import { useRecoilState, useRecoilValue } from 'recoil';
import { zipCodeModalAtom } from '../../atom/zipCodeModalAtom';
import { repsAtom } from '../../atom/repsAtom';
import { authAtom } from '../../atom/authAtom';

const InputWrapper = styled.div`
  position: relative;
  padding: 0 30px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;

  ${MEDIA_QUERIES.mobile(css`
    padding: 0 20px;
    gap: 8px;
  `)}
`;

const ZipCode = styled.div`
  font-family: var(--font-secondary);
  margin: 10px 0 30px 0;
  text-align: center;
`;

const ResultsWrapper = styled.div`
  overflow-y: auto;
  color: var(--white);
  border-top: 1px solid var(--gray);
  width: 100%;
`;

const Results = styled.div`
  display: flex;
  flex-direction: column;
  ${GrowBottomAnimation()}
`;

const sortRepFn = (a: Rep, b: Rep) => a.full_name.localeCompare(b.full_name);

export const SearchZipCodeModal = () => {
  const navigate = useNavigate();
  const [{ showZipModal, urlParamZip }, setCodeModal] =
    useRecoilState(zipCodeModalAtom);
  const [{ repsFilter, reps }, setReps] = useRecoilState(repsAtom);
  const {
    userDetails: { zip },
  } = useRecoilValue(authAtom);

  useEffect(() => {
    if (zip || urlParamZip) {
      setReps((old) => ({ ...old, repsFilter: zip ?? urlParamZip }));
    }
  }, [zip, setReps, urlParamZip]);

  useEffect(() => {
    const reps = repsData
      .filter(
        (rep) =>
          repsFilter !== '' &&
          ((isNaN(parseInt(repsFilter)) &&
            (rep.full_name.toLowerCase().includes(repsFilter.toLowerCase()) ||
              rep.state.toLowerCase().includes(repsFilter.toLowerCase()))) ||
            (!isNaN(parseInt(repsFilter)) && rep.zips.includes(repsFilter))),
      )
      .sort(sortRepFn);

    setReps((old) => ({ ...old, reps: reps }));
  }, [repsFilter, setReps]);

  const setFilter = useCallback(
    (repsFilter: string) => setReps((old) => ({ ...old, repsFilter })),
    [setReps],
  );

  const handleClick = useCallback(
    (id: string) => {
      // @ts-ignore
      window.dataLayer.push({
        event: 'rep_clicked',
        rep_id: id,
        filter: repsFilter,
      });

      setCodeModal((old) => ({ ...old, showZipModal: false }));

      window.scrollTo({ top: 0, behavior: 'smooth' });
      navigate(`/call/${id}/0`);
    },
    [navigate, repsFilter, setCodeModal],
  );

  return (
    <Modal
      open={showZipModal}
      close={() => setCodeModal((old) => ({ ...old, showZipModal: false }))}
    >
      <InputWrapper>
        <SearchIcon />
        <GoogleMapsAutoComplete onPlaceSelected={(e) => setFilter(e)} />
      </InputWrapper>

      {reps.length > 0 && (
      <>
        <ZipCode>Representatives for {repsFilter}</ZipCode>
        <ResultsWrapper>
          <Results>
            <List
              height={10 * 128}
              width={1000}
              rowCount={reps.length}
              rowHeight={128}
              rowRenderer={({ index, key, style }) => (
                <RepDetails
                  key={key}
                  rep={reps[index]}
                  style={style}
                  index={index}
                  onClick={handleClick}
                />
              )}
            />
          </Results>
        </ResultsWrapper>
      </>
      )}
    </Modal>
  );
};
