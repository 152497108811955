import React from 'react';
import styled from 'styled-components';

const TOSPageWrapper = styled.div`
  width: 100dvw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Paragraph = styled.p`
  font-family: var(--font-secondary);
  font-size: 16px;
  text-align: justify;
  text-indent: 2em;
  width: 80%;
  margin-top: 20px;
`;

export const TOSPage: React.FC = () => {
  return (
    <TOSPageWrapper>
      <h1>TERMS OF SERVICE</h1>
      <Paragraph>
        Welcome to OneMinADay.com (“Site”, “we,” “us,” or “our”). The following
        Terms of Use govern your access to and use of the Site, including any
        content, functionality and services (“Services”) offered on and through
        the Site. Please take the time to read the full Terms of Use. You accept
        and agree to be bound by these Terms of Use when you use the Site,
        without limitation, when you view or access content or videos on the
        Site. This Site is offered and available to users who are 18 years of
        age or older and reside in the United States or any of its territories
        or possessions. By using this Site or registering as a user, you
        represent and warrant that you are of legal age to form a binding
        contract with us and meet all of the foregoing eligibility requirements,
        and you are agreeing to legally bind yourself to these Terms of Use. If
        you do not meet all of these requirements or do not wish to be bound by
        these Terms of Use, you must not access or use the Site.
      </Paragraph>
      <Paragraph>
        IMPORTANT NOTICE – THESE TERMS OF USE CONTAIN AN ARBITRATION AGREEMENT
        AND CLASS ACTION WAIVER, WHICH AFFECT YOUR LEGAL RIGHTS. YOU SHOULD
        REVIEW THIS DOCUMENT IN ITS ENTIRETY BEFORE ACCESSING OR USING THE SITE
        OR SERVICES.
      </Paragraph>
      <Paragraph>
        I. Governing Terms These Terms of Use, along with any additional terms
        and conditions that are referenced herein or that are presented
        elsewhere on the Site in relation to a specific service or feature
        (collectively, “Terms of Use”) and our Privacy Policy, set forth the
        terms and conditions that apply to your use of the Site. By using the
        Site, you agree to comply with all of the terms and conditions hereof.
        If you do not agree to these Terms of Use, you must not access or use
        this Site.
      </Paragraph>
      <Paragraph>
        II. Account Membership Visitors to the Site who are United States
        citizens or Permanent Residents of the United States may create an
        account on this Site. By creating an account on the Site and by
        providing your email address, you are affirmatively opting in to receive
        communications from us. Once you create an account and, as such,
        affirmatively accept our invitation to become a member, you will receive
        an annual notice of membership renewal. Membership shall be deemed
        automatically renewed unless you choose to unsubscribe from further
        communications from us.
      </Paragraph>
      <Paragraph>
        III. Limited License; Changes to the Terms of Use Upon your acceptance
        of these Terms of Use, we grant you a revocable, non-exclusive,
        non-transferable, limited license to access and view this Site and the
        Site materials. All rights not expressly granted to you herein are
        reserved by us; there are no implied licenses under these Terms of Use.
        The license granted to you in these Terms of Use is expressly
        conditioned on your continued compliance with these Terms of Use. Please
        note that we may revise and update these Terms of Use from time to time
        in our sole discretion. All changes are effective immediately when we
        post them and apply to all access to and use of the Site thereafter.
        Your continued use of the Site as a visitor or registered user following
        the posting of revised Terms of Use means that you accept and agree to
        the changes. You are expected to check this Site, so you are aware of
        any changes, as they are binding on you. If you object to any provision
        of these Terms of Use or any subsequent modification, or become
        dissatisfied with the Site in any way, your sole recourse is to
        immediately terminate your use of the Site.
      </Paragraph>
      <Paragraph>
        IV. Changes to the Site We may update the content on this Site from time
        to time, but its content is not necessarily complete or up to date. Any
        of the material or content on the Site may be out-of-date at any given
        time, and we are under no obligation to update such material or content.
      </Paragraph>
      <Paragraph>
        V. Accessing the Site We reserve the right to withdraw or amend this
        Site, and any service or material we provide on the Site, in our sole
        discretion without notice. We will not be liable if, for any reason, all
        or any part of the Site is unavailable at any time or for any period.
        From time to time, we may restrict access to some parts of the Site, or
        the entire Site, to users. You are responsible for: • Making all
        arrangements necessary for you to have access to the Site. • Ensuring
        that all persons who access the Site through your internet connection
        are aware of these Terms of Use and comply with them.
      </Paragraph>
      <Paragraph>
        VI. Prohibited Uses and Conduct You may use the Site only for lawful
        purposes and in accordance with these Terms of Use. You agree not to use
        the Site: • In any way that violates any applicable international,
        federal, state, or local law or regulation. • To impersonate or attempt
        to impersonate us, our employees, another user or any other person or
        entity or allow another user to use or to access the Site or Services. •
        To engage in any other conduct that restricts or inhibits anyone's use
        or enjoyment of the Site, or which, as determined by us, may harm us or
        users of the Site or expose them to liability. Additionally, you agree
        not to: • Use the Site in any manner that could disable, overburden,
        damage, or impair the site or interfere with any other party's use of
        the Site, including their ability to engage in real time activities
        through the Site. • Use any robot, spider or other automatic device,
        process or means to access the Site for any purpose, including
        monitoring or copying any of the material on the Site. • Use any manual
        process to monitor or copy any of the material on the Site or for any
        other unauthorized purpose without our prior written consent. • Use any
        device, software or routine that interferes with the proper working of
        the Site. • Introduce any viruses, trojan horses, worms, logic bombs or
        other material which is malicious or technologically harmful. • Attempt
        to gain unauthorized access to, interfere with, damage or disrupt any
        parts of the Site or Service, the server on which the Site is stored, or
        any server, computer or database connected to the Site. • Attempt to
        manipulate our Site (or any of the features or functionality of the
        Site) in an unfair, improper or inappropriate manner, or in a manner
        that creates an unfair, improper or inappropriate advantage or benefit
        for you or any other user (all as determined by us in our sole
        judgment); • Attempt to decipher, decompile, disassemble or reverse
        engineer any of the software used to provide the Site or Services. •
        Attack the Site via a denial-of-service attack or a distributed
        denial-of-service attack. • Use this Site for commercial purposes or in
        any manner that competes with any of the Services of the Site. • Remove,
        alter, bypass, avoid, interfere with, or circumvent any copyright,
        trademark, or other proprietary rights or notices marked on the Site or
        Services; • Otherwise attempt to interfere with the proper working of
        the Site. You agree that we may take any measures we deem appropriate,
        in our sole discretion, to prevent the violation of, and to enforce,
        these Terms of Use. You agree that we may take any actions permitted or
        required by law (including the suspension or termination of your access
        to the Site and the Services) if we believe, in our sole discretion,
        that you are engaging in activities that (i) violate these provisions
        (or other terms in these Terms of Use), (ii) could expose us or our
        vendors or business partners to liability, or (iii) could harm our
        business reputation. VII. Ownership of the Site and Our Content The
        Site, and its Content, are provided solely for your personal,
        non-commercial use in accordance with these Terms of Use. You agree that
        the Site and Services (and all content contained therein) are protected
        by intellectual property and other laws, and are our property
        (hereinafter “Property”). You may not, and you agree you will not,
        modify, copy, reproduce, prepare derivative works from, license, sell,
        rent, transfer, translate, redistribute, transmit, republish, reverse
        engineer, decompile, or disassemble any Property in any way without our
        prior written permission. The Content of the Site is further protected
        under United States copyright, trademark, patent, trade secret, and
        other intellectual property or proprietary rights laws, to the extent
        applicable. You must abide by all copyright notices, information, or
        restrictions contained in or attached to any Property. All rights not
        expressly granted herein are reserved to us.
      </Paragraph>
      <Paragraph>
        VIII. Ownership of and Our Right to Your Materials At its sole
        discretion, we may make available to you the ability to post, submit,
        email or otherwise make available on the Site or the Services (including
        via email to us) information, text or materials ("Your Materials"). You
        are entirely responsible for Your Materials, and you represent and
        warrant that Your Materials do not include confidential or proprietary
        information or violate any other party's intellectual property rights.
        As between you and us, you retain ownership and any intellectual
        property rights in any materials that are contained in Your Materials,
        subject to the non-exclusive rights that you grant to us in the Terms of
        Use. You grant us an irrevocable, perpetual, non-exclusive,
        royalty-free, fully-paid, transferrable, sublicensable, worldwide
        license to use, reproduce, modify, transmit, distribute, publicly
        display and perform, prepare derivative works of, incorporate into other
        works, and otherwise exploit, Your Materials in any form, technology or
        media now known or hereafter developed. You hereby waive any moral
        rights you may have in Your Materials under the laws of any
        jurisdiction. You agree we may exercise any of these rights without
        compensation or attribution to you.
      </Paragraph>
      <Paragraph>
        IX. Social Media Sites As part of the Services, the Site may also
        provide links to officially monitored and curated social media channels
        that also display Property and content. Your use of these linked our
        social media channels is also subject to these Terms of Use, as well as
        the Terms of Use of the social platform from which you access this
        content. Our social medial channels may include, without limitation, our
        Facebook page, Twitter account, Instagram account, YouTube channel,
        LinkedIn page, and any our mobile apps. We reserve all rights relating
        to our social media channels, including, without limitation, Adding,
        removing, or modifying any content; Blocking harassing and disruptive
        users; Discontinuing any of our social media channels at any time. We do
        not warrant that the information on these social media channels is
        complete, reliable, useful, or otherwise accurate. Do not rely solely on
        the information presented on these social media platforms for
        information or advice of any kind. Further, we do not create nor control
        any opinions or statements expressed by others within its social media
        channels, including those that Follow, Like, re-Tweet, re-post, or
        otherwise share our content therein, nor does any similar action
        undertaken by us concerning another user’s content constitute an
        endorsement of that user.
      </Paragraph>
      <Paragraph>
        X. User-Generated Content The Site may contain sections, forums, or
        other interactive features ("Interactive Areas") in which you may post
        or upload user-generated content (“User-Generated Content”). You are
        solely responsible for your use of any Interactive Areas, and you use
        them at your own risk. Interactive Areas are available for individuals
        aged 18 years or older. By providing any such submission, any material
        will be deemed and remain our property. You represent and warrant that
        the owner of such User-Generated Content has expressly granted us a
        royalty-free, perpetual, irrevocable, world-wide non-exclusive license
        to use, reproduce, display, modify, publish, edit, translate,
        distribute, perform, and create derivative works from Content in any
        media or medium, form, or forum known of hereafter developed.
      </Paragraph>
      <Paragraph>
        XI. Monitoring We shall have the right, but not obligation, to monitor
        user content (“User-Generated Content”) posted or uploaded to the Site
        to determine compliance with these Terms of Use or to satisfy any law,
        regulation or authorized government request. Although we have no
        obligation to monitor, screen, edit or remove any of the User-Generated
        Content, we reserve the right, in its sole and absolute discretion, to
        screen, edit, refuse to post or remove, without notice, any
        User-Generated Content at any time and for any reason. Our decision to
        monitor and/or modify User-Generated Content does not constitute, nor
        shall it be deemed to constitute, any responsibility or liability in any
        manner on our part in connection with or arising from use by you of the
        Site.
      </Paragraph>
      <Paragraph>
        XII. Endorsements You acknowledge that the opinions and recommendations
        contained in the Site are not necessarily ours, or likewise endorsed by
        us. All statements and opinions expressed in these materials, as well as
        all articles and other content, save content expressly provided by us,
        are solely the opinions and responsibility of the person or entity
        providing those materials. The Site may provide links to other sites
        which are not under our control. These links are provided for
        convenience and reference only and are not intended as an endorsement by
        us. We are not responsible or liable to you or any other third party for
        the content, usefulness, or accuracy of any material provided by a third
        party. Any reliance you place on such information is strictly at your
        own risk.
      </Paragraph>
      <Paragraph>
        XIII. Termination We may terminate or suspend these Terms of Use at any
        time without notice to you. Without limiting the foregoing, we shall
        have the right to immediately terminate your access in the event of any
        conduct by you which we, in our sole discretion, considers to be
        unacceptable, or in the event of any breach by you of these Terms of
        Use.
      </Paragraph>
      <Paragraph>
        XIV. Trademarks All names, logos, product and service names, designs,
        and slogans on this Site are our trademarks and/or their respective
        owners. No affiliation or endorsement is intended or implied. You must
        not use such marks without our prior written permission.
      </Paragraph>
      <Paragraph>
        XV. Information About You and Your Visits to the Site All information we
        collect on this Site is subject to our Privacy Policy. By using the
        Site, you consent to all actions taken by us with respect to your
        information in compliance with the Privacy Policy.
      </Paragraph>
      <Paragraph>
        XVI. Confidentiality on the Internet Use of the Internet is solely at
        your own risk and is subject to all applicable international, federal,
        state, and local laws and regulations. While we have endeavored to
        create a secure and reliable site, please be advised that the
        confidentiality of any communication or material transmitted to us over
        the Internet cannot be guaranteed. Consequently, we are not responsible
        for the security of any information transmitted via the Internet, the
        accuracy of the information contained on this Site, or for the
        consequences of any reliance on such information. You must make your own
        determination as to these matters.
      </Paragraph>
      <Paragraph>
        XVII. Geographic Restrictions We provide this Site for use only by
        persons located in the United States. We make no claims that the Site or
        any of its content is accessible or appropriate outside of the United
        States, as it is intended for use by US residents only. Access to the
        Site may not be legal by certain persons or in certain countries. If you
        access the Site from outside the United States, you do so on your own
        initiative and are responsible for compliance with local laws.
      </Paragraph>
      <Paragraph>
        XVIII. Disclaimer of Warranties You understand that we cannot and does
        not guarantee or warrant that the Site will be free of viruses or other
        destructive code. You are responsible for implementing sufficient
        procedures and checkpoints to satisfy your particular requirements for
        anti-virus protection and accuracy of data input and output, and for
        maintaining a means external to our site for any reconstruction of any
        lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A
        DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY
        HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER
        PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SITE
        OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE OR TO YOUR
        DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY SITE LINKED TO IT.
        YOUR USE OF THE SITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED
        THROUGH THE SITE IS AT YOUR OWN RISK. THE SITE, ITS CONTENT, AND ANY
        SERVICES OR ITEMS OBTAINED THROUGH THE SITE ARE PROVIDED ON AN "AS IS"
        AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER
        EXPRESS OR IMPLIED. NEITHER WE NOR ANY PERSON ASSOCIATED WITH US MAKES
        ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
        SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE SITE.
        WITHOUT LIMITING THE FOREGOING, NEITHER WE NOR ANYONE ASSOCIATED WITH US
        REPRESENTS OR WARRANTS THAT THE SITE, ITS CONTENT, OR ANY SERVICES OR
        ITEMS OBTAINED THROUGH THE SITE WILL BE ACCURATE, RELIABLE, ERROR-FREE
        OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE
        SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
        COMPONENTS OR THAT THE SITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH
        THE SITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. WE HEREBY
        DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
        STATUTORY OR OTHERWISE, INCLUDING, BUT NOT LIMITED, TO ANY WARRANTIES OF
        MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.
        THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR
        LIMITED UNDER APPLICABLE LAW.
      </Paragraph>
      <Paragraph>
        XIX. Limitation on Liability IN NO EVENT WILL WE, OUR AFFILIATES OR
        THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR
        DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY,
        ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE
        SITE, ANY SITES LINKED TO IT, ANY CONTENT ON THE SITE OR SUCH OTHER
        SITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE OR SUCH OTHER
        SITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
        CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO,
        PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF
        REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS
        OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT
        (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF
        FORESEEABLE. THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE
        EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
      </Paragraph>
      <Paragraph>
        XX. Indemnification You agree to defend, indemnify and hold us, our
        affiliates, licensors and service providers, and our respective
        officers, directors, employees, contractors, agents, licensors,
        suppliers, successors and assigns harmless from and against any claims,
        liabilities, damages, judgments, awards, losses, costs, expenses or fees
        (including reasonable attorneys' fees) arising out of or relating to
        your violation of these Terms of Use or your use of the Site or
        Services, including, but not limited to, any use of the Site or Services
        other than as expressly authorized in these Terms of Use or your use of
        any information obtained from the Site or Services. You agree we have
        the right to hire counsel of our own choosing in connection with, and to
        assume the exclusive defense and control of, any matter subject to
        indemnification by you, and doing so will in no way limit your
        indemnification obligations hereunder. In any litigation, you will
        cooperate with us in asserting any available defenses.
      </Paragraph>
      <Paragraph>
        XXI. Limitation on Time to File Claims ANY CAUSE OF ACTION OR CLAIM YOU
        MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE, THE SITE, OR
        SERVICES MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION
        ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
      </Paragraph>
      <Paragraph>
        XXII. Dispute Resolution; Arbitration PLEASE READ THIS SECTION CAREFULLY
        – IT CONTAINS AN ARBITRATION AGREEMENT AND CLASS ACTION WAIVER, WHICH
        AFFECT YOUR LEGAL RIGHTS. Initial Dispute Resolution. We would like to
        try to work disputes out with you informally. Before filing a claim
        against us, you agree to try to resolve the dispute informally by
        contacting bringthemhomeny@gmail.com. We will try to resolve the dispute
        informally by contacting you via email. If a dispute is not resolved
        within 15 days of submission, you or we may bring a formal arbitration
        proceeding as described below. Agreement to Arbitrate. You and we agree
        to resolve through final and binding arbitration any claims relating to
        these Terms of Use, the Site, the Services, and any content offered on
        or through the Site, except as set forth under Exceptions to Agreement
        to Arbitrate below. The arbitration shall be held in New York, New York
        For residents outside the United States, arbitration shall also be
        initiated in New York, New York You and we agree to submit to the
        personal jurisdiction of any or state or federal court in New York, New
        York to compel arbitration, stay proceedings pending arbitration, or to
        confirm, modify, vacate or enter judgment on the aware entered by the
        arbitrator. Arbitration Procedures. Any and all disputes or claims
        arising out of, or relating to, a breach of these Terms of Use, the
        Site, the Services, and any content offered on or through the Site
        (including any transactions conducted through the Services, or otherwise
        conducted on our behalf) (“Claims”) will be settled by binding
        arbitration before a single arbitrator appointed by the American
        Arbitration Association (“AAA”) in accordance with its then governing
        rules and procedures. Arbitration and Attorneys’ Fees. You are
        responsible for all costs that you may incur in the arbitration
        including, but not limited to filing fees, attorneys’ fees, and expert
        witness costs unless we are otherwise specifically required to pay such
        fees under applicable law. The decision of the arbitrator will be in
        writing and binding and conclusive on us and you, and judgment to
        enforce the decision may be entered by any court of competent
        jurisdiction. We and you agree that dispositive motions, including
        without limitation, motions to dismiss and motions for summary judgment,
        will be allowed in the arbitration. The arbitrator must follow these
        Terms of Use and can award the same damages and relief as a court,
        including injunctive or other equitable relief and attorneys’ fees.
        Notwithstanding the foregoing, you agree not to seek any attorneys’ fees
        and expert witness costs unless the arbitrator finds that a claim or
        defense was frivolous or asserted for an improper purpose. We and you
        understand that, absent this mandatory arbitration provision, we and you
        would have the right to sue in court and have a jury trial. We and you
        further understand that, in some instances, the costs of arbitration
        could exceed the costs of litigation and the right to discovery may be
        more limited in arbitration than in court. If is the prevailing party in
        the arbitration, applicable law may allow the arbitrator to award
        attorneys’ fees and costs to us. Severability; Waiver of Jury Trial. If
        any clause within these arbitration provisions is found to be illegal or
        unenforceable, that specific clause will be severed from these
        arbitration provisions, and the remainder of the arbitration provisions
        will be given full force and effect. In the event some or all of these
        arbitration provisions are determined to be unenforceable for any
        reason, or if a claim, dispute or controversy is brought that is found
        by a court to be excluded from the scope of these arbitration
        provisions, we and you agree to waive, to the fullest extent allowed by
        law, any trial by jury. The terms of these arbitration provisions will
        also apply to any claims asserted by you against any of our present or
        future parent or affiliated company to the extent that any such claims
        arise out of your access to, and/or use of the Services, and/or the
        provision of content, services, and/or technology on or through the
        Services. Opt-Out of Agreement to Arbitrate. You have the right to
        opt-out and not be bound by the arbitration by sending written notice of
        your decision to opt-out to the following email address:
        bringthemhomeny@gmail.com. The notice must be sent within 30 days of or
        your first acceptance of these Terms of Use of the Site; otherwise, you
        shall be bound to arbitrate disputes in accordance with the terms above.
        If you opt-out of these arbitration provisions, we will also not be
        bound by them. No Class Actions PLEASE READ THIS SECTION CAREFULLY – IT
        MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS. You may only resolve
        disputes with us on an individual basis, and you may not bring a claim
        as a plaintiff or a class member in a class, consolidated, or
        representative action. You agree that class arbitrations, class actions,
        private attorney general actions, and consolidation with other
        arbitrations aren’t allowed. BY AGREEING TO THIS ARBITRATION AGREEMENT,
        YOU ARE GIVING UP YOUR RIGHT TO GO TO COURT, INCLUDING YOUR RIGHT TO A
        JURY TRIAL AND TO PARTICIPATE IN A CLASS ACTION. YOU UNDERSTAND THAT BY
        AGREEING TO THIS ARBITRATION AGREEMENT AND CLASS ACTION WAIVER, YOU MAY
        ONLY BRING CLAIMS AGAINST US AND THE OUR RELEASED PARTIES IN AN
        INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
        PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING. IF YOU DO NOT AGREE
        TO ARBITRATION, YOU SHOULD OPT OUT OF THIS ARBITRATION PROVISION PER THE
        TERMS OF SERVICE. OTHERWISE, YOU ARE NOT AUTHORIZED TO USE THIS SITE IN
        ANY WAY.
      </Paragraph>
      <Paragraph>
        XXIII. Governing Law and Jurisdiction In the event that the agreement to
        arbitration is found not to apply to you or your claim, you and we agree
        that any judicial proceeding will be brought in the federal or state
        courts in the district of New York, New York. Both you and we consent to
        venue and personal jurisdiction there. You agree that the arbitration
        provision is made pursuant to a transaction involving interstate
        commerce and shall be governed by the Federal Arbitration Act ("FAA"),
        and not by any state law concerning arbitration. You otherwise agree
        that the law of the District of Columbia governs these Terms of Service
        and any claim or dispute that you may have against us, without regard to
        the District of Columbia’s conflict of laws rules.
      </Paragraph>
      <Paragraph>
        XXIV. Waiver and Severability No waiver by us of any term or condition
        set forth in these Terms of Use or in the Subscription Services User
        shall be deemed a further or continuing waiver of such term or condition
        or a waiver of any other term or condition, and any failure by us to
        assert a right or provision under these Terms of Service shall not
        constitute a waiver of such right or provision. If any provision of
        these Terms of Use is held by a court or other tribunal of competent
        jurisdiction to be invalid, illegal or unenforceable for any reason,
        such provision shall be eliminated or limited to the minimum extent such
        that the remaining provisions of the Terms of Use will continue in full
        force and effect.
      </Paragraph>
      <Paragraph>
        XXV. Entire Agreement These Terms of Use, and any supplemental terms,
        policies, rules and guidelines posted on the Site and the Services, in
        addition to the Community Rules, constitute the entire agreement between
        you and us and supersede all previous written or oral agreements. The
        paragraph headings in these Terms of Use, shown in boldface type, are
        included only to help make these Terms of Service easier to read and
        have no binding effect.
      </Paragraph>
      <Paragraph>
        XXVI. Assignment We may assign this agreement through your acceptance of
        these Terms of Use at any time, including, without limitation, to any
        affiliated company, or as part of the sale to, merger with, or other
        transfer of our company to another entity. You may not assign, transfer
        or sublicense the limited license or any other rights granted to you
        under these Terms of Use to anyone else and any attempt to do so in
        violation of this section shall be null and void.
      </Paragraph>
      <Paragraph>
        XXVII. Procedures for Notification of Copyright Infringement If you
        believe that your copyrighted work has been copied and is accessible on
        this Site in a way that constitutes copyright infringement, then you may
        submit a notification pursuant to the Digital Millennium Copyright Act
        (“DMCA”), 17 U.S.C. § 512, by providing our copyright agent with the
        following information: A physical or electronic signature of a person
        authorized to act on behalf of the owner of an exclusive right that is
        allegedly infringed; Identification of the copyrighted work claimed to
        have been infringed, or, if multiple copyrighted works at a single
        online site are covered by a single notification, a representative list
        of such works at that site; Identification of the material that is
        claimed to be infringing or to be the subject of infringing activity and
        that is to be removed or access to which is to be disabled, and
        information reasonably sufficient to permit us to locate the material;
        Information reasonably sufficient to permit us to contact the
        complaining party, such as an address, telephone number, and, if
        available, an electronic mail address at which the complaining party may
        be contacted; A statement that the complaining party has a good faith
        belief that use of the material in the manner complained of is not
        authorized by the copyright owner, its agent, or the law; and A
        statement that the information in the notification is accurate, and
        under penalty of perjury, that the complaining party is authorized to
        act on behalf of the owner, of an exclusive right that is allegedly
        infringed. See 17 U.S.C. § 512 for more information. DMCA Notices Our
        agent for notice of copyright infringement can be reached as follows:
        bringthemhomeny@gmail.com. All subject lines should read: “DMCA Notice.”
        This contact information is only for reporting copyright infringement.
      </Paragraph>
      <Paragraph>
        Thank you for visiting https://oneminaday.com/. Last Modified: October
        26, 2023
      </Paragraph>
    </TOSPageWrapper>
  );
};
