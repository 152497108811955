import styled, { css } from 'styled-components';
import { MEDIA_QUERIES } from './mediaQueries';
import { useEffect, useRef, useState } from 'react';

const Backdrop = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4px);
  opacity: ${({ open }) => (open ? 1 : 0)};
  z-index: ${({ open }) => (open ? 100 : -1)};
  transition:
    opacity 0.2s ease-in-out,
    z-index ${({ open }) => (open ? '' : '0s 0.2s ease-in-out')};
`;

const ModalWrapper = styled.div<{
  open: boolean;
  isCentered: boolean;
  withPadding: boolean;
}>`
  width: 500px;
  max-width: 80vw;
  height: fit-content;
  position: absolute;
  top: ${({ isCentered }) => (isCentered ? '50%' : '100px')};
  left: 50%;
  transform: ${({ isCentered, open }) =>
    `${isCentered ? 'translate(-50%, -50%)' : 'translateX(-50%)'} scale(${
      open ? 1 : 0
    })`};
  transform-origin: center;
  max-height: ${({ isCentered }) =>
    isCentered ? '95vh' : 'calc(100vh - 100px)'};
  overflow: scroll;
  border-radius: var(--br);
  background-color: var(--black);
  opacity: ${({ open }) => (open ? 1 : 0)};
  transition:
    transform 0.2s 0.1s ease-in-out,
    opacity 0.2s 0.1s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--white);

  ${MEDIA_QUERIES.smallTablet(css`
    max-width: 90vw;
  `)}

  ${MEDIA_QUERIES.mobile(css`
    max-width: 95vw;
  `)}

  ${({ withPadding }) =>
    withPadding &&
    css`
      padding: 100px 60px 30px;

      ${MEDIA_QUERIES.smallTablet(css`
        padding: 80px 40px 25px;
      `)}

      ${MEDIA_QUERIES.mobile(css`
        padding: 60px 30px 20px;
      `)}
    `}
`;

const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 28px;
  background: none;
  border: none;
  color: var(--white);
  font-size: 28px;
  cursor: pointer
    ${MEDIA_QUERIES.smallTablet(css`
      top: 16px;
      right: 20px;
      font-size: 20px;
    `)};
`;

interface ModalProps {
  open: boolean;
  showCloseButton?: boolean;
  close?: () => void;
  isCentered?: boolean;
  children: React.ReactNode;
  withPadding?: boolean;
}

export const Modal = ({
  open,
  isCentered = false,
  children,
  close,
  showCloseButton,
  withPadding = false,
}: ModalProps) => {
  const [shouldRender, setShouldRender] = useState(false);
  const backdropRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { current } = backdropRef;
    const hideAfterTransition = () => {
      setShouldRender(false);
    };

    if (open) {
      setShouldRender(true);
    } else {
      current?.addEventListener('transitionend', hideAfterTransition);
    }

    return () => {
      current?.removeEventListener('transitionend', hideAfterTransition);
    };
  }, [open]);

  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && open) {
        close && close();
      }
    };
    document.addEventListener('keydown', handleEsc);
    return () => document.removeEventListener('keydown', handleEsc);
  });

  return (
    <Backdrop open={open} onClick={close} ref={backdropRef}>
      <ModalWrapper
        open={open}
        isCentered={isCentered}
        onClick={(e) => e.stopPropagation()}
        withPadding={withPadding}
      >
        {showCloseButton && close && (
          <CloseButton onClick={close}>&times;</CloseButton>
        )}
        {shouldRender && children}
      </ModalWrapper>
    </Backdrop>
  );
};
