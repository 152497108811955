import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { RedButton } from '../common/Button';
import { formatPhone } from '../callPage/utils';
import { Input } from '../common/Input';
import {
  validateEmail,
  validatePhone,
  validDisplayName,
} from '../../util/dataValidation';
import { addReminder } from '../../api/reminderApi';
import { ReminderApiBody } from 'oneminuteaday-common';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authAtom } from '../../atom/authAtom';

const FormContainer = styled.div<{ shrink?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ shrink }) => (shrink ? '0' : 'auto')};
  transition: height 1s ease-in-out;
  overflow: hidden;
`;

const CheckBoxWrapper = styled.div`
  color: var(--white);
  display: flex;
  gap: 8px;
  justify-content: center;
  font-family: var(--font-secondary);
  margin-bottom: 20px;
  font-size: 14px;

  & input {
    accent-color: var(--red);
  }
`;

const ErrorMessage = styled.div`
  font-family: var(--font-secondary);
  font-size: 14px;
  color: var(--red);
  margin-bottom: 10px;
`;

const StyledButton = styled(RedButton)`
  align-self: center;
  margin-top: 16px;
  box-shadow: 0 0 0;
`;

const LoadingSpinner = styled.div<{ hidden: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--red);
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  z-index: ${({ hidden }) => (hidden ? -1 : 2)};
  transition:
    opacity 0.1s ease-in-out,
    z-index ${({ hidden }) => (hidden ? '0s 0.1s ease-in-out' : '')};
`;

const LoginLink = styled(Link)`
  color: var(--red);
`;

const InputsContainerStyle = styled.div`
  margin: auto;
`;

const RequireUserLogin = styled.div`
  margin: 10px;
  text-align: center;
  font-size: 20px;
  font-family: var(--font-secondary);
`;

interface ContactFormProps {
  isOpen: boolean;
  filter: string;
  firstRepId: string;
  onSuccess: (phoneNumber: string) => void;
}

export const ReminderForm: React.FC<ContactFormProps> = ({
  isOpen,
  filter,
  firstRepId,
  onSuccess,
}) => {
  const [errorMessage, setErrorMessage] = useState<React.ReactNode | null>(
    null,
  );
  const {
    isAuth,
    userDetails: { phoneNumber: userPhoneNumber, displayName },
  } = useRecoilValue(authAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [name, setName] = useState(displayName);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(userPhoneNumber);
  const [isRemindMeChecked, setIsRemindMeChecked] = useState(true);
  const [requireUserLogin, setRequireUserLogin] = useState(false);

  const reset = useCallback(() => {
    setName(displayName);
    setEmail('');
    setPhoneNumber(userPhoneNumber);
    setRequireUserLogin(false);
  }, [
    userPhoneNumber,
    displayName,
    setEmail,
    setName,
    setPhoneNumber,
    setRequireUserLogin,
  ]);

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  const enableSubmit =
    validatePhone(phoneNumber) &&
    (!email || validateEmail(email)) &&
    validDisplayName(name);

  const handleSubmit = useCallback(async () => {
    if (!enableSubmit) {
      return;
    }

    setErrorMessage(null);
    setIsLoading(true);
    setIsSuccess(false);

    // @ts-ignore
    window.dataLayer.push({
      event: 'contact_form_submitted',
    });

    const body: ReminderApiBody = {
      filter,
      firstRepId,
      phoneNumber,
      email,
      recurring: isRemindMeChecked,
      displayName: name,
    };

    try {
      const { requireUserLogin } = await addReminder(body);
      setRequireUserLogin(requireUserLogin);
      setIsSuccess(!requireUserLogin);
      if (!requireUserLogin) {
        onSuccess(phoneNumber);
      }
    } catch (e) {
      setErrorMessage(true);
      setIsSuccess(false);
    } finally {
      setIsLoading(false);
    }
  }, [
    email,
    phoneNumber,
    name,
    isRemindMeChecked,
    filter,
    firstRepId,
    setErrorMessage,
    setIsSuccess,
    setIsLoading,
    enableSubmit,
    onSuccess,
  ]);

  const checkboxInput = useMemo(
    () => (
      <CheckBoxWrapper>
        <input
          type="checkbox"
          id="remindme"
          checked={isRemindMeChecked}
          onChange={() => setIsRemindMeChecked(!isRemindMeChecked)}
        />
        <label htmlFor="remindme">Remind me Daily</label>
      </CheckBoxWrapper>
    ),
    [isRemindMeChecked, setIsRemindMeChecked],
  );

  const nameInput = useMemo(
    () => (
      <>
        <Input
          type="text"
          placeholder="Name (required)"
          value={name}
          disabled={isAuth && !!displayName}
          onChange={(e) => {
            setName(e.target.value);
          }}
          hasError={!!name && !validDisplayName(name)}
        />
      </>
    ),
    [name, isAuth, displayName, setName],
  );

  const phoneInput = useMemo(
    () => (
      <>
        <Input
          type="phone"
          placeholder="Phone (reqiured)"
          value={phoneNumber}
          disabled={isAuth}
          onChange={(e) => {
            setPhoneNumber(formatPhone(e.target.value));
          }}
          hasError={!!phoneNumber && !validatePhone(phoneNumber)}
        />
      </>
    ),
    [phoneNumber, setPhoneNumber, isAuth],
  );

  const emailInput = useMemo(
    () => (
      <>
        <Input
          type="email"
          placeholder="Email (optional)"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          hasError={!!email && !validateEmail(email)}
        />
      </>
    ),
    [email, setEmail],
  );

  const inputs = useMemo(
    () => (
      <InputsContainerStyle>
        {checkboxInput}

        {nameInput}

        {phoneInput}

        {emailInput}

        {errorMessage && (
          <ErrorMessage style={{ margin: '0 auto' }}>
            {errorMessage}
          </ErrorMessage>
        )}
        {requireUserLogin ? (
          <RequireUserLogin>
            Please <LoginLink to="/login">login</LoginLink> to add a reminder
          </RequireUserLogin>
        ) : (
          <StyledButton onClick={handleSubmit} disabled={!enableSubmit}>
            <LoadingSpinner hidden={!isLoading}>
              <i className="fa fa-spinner fa-spin" />
            </LoadingSpinner>
            Remind me tomorrow
          </StyledButton>
        )}
      </InputsContainerStyle>
    ),
    [
      emailInput,
      phoneInput,
      nameInput,
      handleSubmit,
      isLoading,
      errorMessage,
      enableSubmit,
      checkboxInput,
      requireUserLogin,
    ],
  );

  return <FormContainer shrink={isSuccess}>{inputs}</FormContainer>;
};
