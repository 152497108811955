import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { logoutApi } from '../../api/authApi';
import { useSetRecoilState } from 'recoil';
import { authAtom } from '../../atom/authAtom';

export const Logout = () => {
  const setAuth = useSetRecoilState(authAtom);
  const navigate = useNavigate();

  useEffect(() => {
    logoutApi(setAuth);
    navigate('/');
  }, [setAuth, navigate]);

  return null;
};
