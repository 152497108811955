import React, { useCallback, useMemo, useState } from 'react';
import { validatePhone, validateEmail } from '../../util/dataValidation';
import { Input, Select } from '../common/Input';
import {
  CloseButton,
  Title,
  Description,
  FormStyle,
  Subtitle,
  SubmitButton,
} from './CellFormStyles';
import { DayCheckbox } from './DayCheckbox';
import { days, validateForm } from './callFormUtil';
import { createContact } from '../../api/callVolunteerApi';

export interface CallerFormContentProps {
  onCloseForm: () => void;
}

export interface CallVolunteerForm {
  fullName: string;
  email: string;
  phoneNumber: string;
  hearAboutUs?: string;
}

export const CallerFormContent = ({ onCloseForm }: CallerFormContentProps) => {
  const [form, setForm] = useState<CallVolunteerForm>({
    fullName: '',
    email: '',
    phoneNumber: '',
  });
  const [selectedDays, setSelectedDays] = useState<{
    [key: string]: boolean;
  }>({});
  const handleSelection = useCallback(
    (day: string) => {
      setSelectedDays((selected) => ({ ...selected, [day]: !selected[day] }));
    },
    [setSelectedDays],
  );

  const isValidForm = useMemo(() => validateForm(form), [form]);

  const handleSubmit = useCallback(async () => {
    createContact(form);
    onCloseForm();
    setSelectedDays({});
  }, [form, onCloseForm, setSelectedDays]);

  return (
    <>
      <CloseButton>
        <div onClick={onCloseForm}>Close</div>
      </CloseButton>
      <Title>Join the callers team</Title>
      <Description>
        We are looking for volunteers to help us make more calls.
      </Description>
      <FormStyle className="flex">
        <Input
          hasError={!!form.fullName && form.fullName.length < 4}
          placeholder="Full name"
          onChange={(e) =>
            setForm((form) => ({ ...form, fullName: e.target.value }))
          }
        />
        <Input
          hasError={!!form.phoneNumber && !validatePhone(form.phoneNumber)}
          placeholder="Phone number"
          onChange={(e) =>
            setForm((form) => ({ ...form, phoneNumber: e.target.value }))
          }
        />
        <Input
          hasError={!!form.email && !validateEmail(form.email)}
          placeholder="Email"
          onChange={(e) =>
            setForm((form) => ({ ...form, email: e.target.value }))
          }
        />
        <div>
          <Subtitle>Select days (optional)</Subtitle>
          {days.map((day) => (
            <DayCheckbox
              key={day}
              day={day}
              isSelected={selectedDays[day]}
              handleSelect={() => handleSelection(day)}
            />
          ))}
        </div>
        <Subtitle>How did you hear about us? (optional)</Subtitle>
        <Select
          onChange={(e) =>
            setForm((form) => ({ ...form, hearAboutUs: e.target.value }))
          }
        >
          <option></option>
          <option>Social media</option>
          <option>Friend</option>
          <option>News</option>
          <option>Other</option>
        </Select>
        <SubmitButton disabled={!isValidForm} onClick={handleSubmit}>
          Submit
        </SubmitButton>
      </FormStyle>
    </>
  );
};
