import { useRecoilState } from 'recoil';
import { zipCodeModalAtom } from '../../atom/zipCodeModalAtom';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

export const useShowCallYourReps = () => {
  const [{ showZipModal }, setZipCodeModal] = useRecoilState(zipCodeModalAtom);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has('show-zip-form')) {
      setZipCodeModal((old) => ({
        ...old,
        showZipModal: true,
        urlParamZip: searchParams.get('show-zip-form') || '',
      }));
    }

    if (!showZipModal) {
      setSearchParams(searchParams.toString().replace('show-zip-form', ''));
    }
  }, [searchParams, setSearchParams, showZipModal, setZipCodeModal]);
};
