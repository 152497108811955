import { CallYourRepsCta } from '../common/CallYourRepsCta';
import styled, { css } from 'styled-components';
import { MEDIA_QUERIES } from '../common/mediaQueries';

const Wrapper = styled.div`
  display: grid;
  background-color: var(--white);
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  z-index: 10;
  width: 100dvw;
  padding: 60px 40px;
  position: sticky;
  top: 0;

  ${MEDIA_QUERIES.laptop(css`
    padding: 60px 0;
    grid-row: 2/3;
  `)}

  ${MEDIA_QUERIES.tablet(css`
    padding: 20px 0;
    grid-row: 2/3;
    max-width: 100vw;
    gap: 20px;
  `)}

  ${MEDIA_QUERIES.smallTablet(css`
    grid-template-columns: 1fr;
    max-width: 100vw;
    padding: 10px 0;
  `)}
`;

export const MainCTA = () => (
  <Wrapper>
    <CallYourRepsCta floating />
  </Wrapper>
);
