import React, { useState } from 'react';
import styled from 'styled-components';
import { isMobile } from '../../utils';

const WHATSAPP_GROUP_LINK = 'https://chat.whatsapp.com/Hv4wyKuxjPz0QktNt3sDtM';
const WhatsappIcon = styled.img.attrs({ alt: '', src: '/Whatsapp.svg' })`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 15px;
  top: 10px;
`;

const Container = styled.a`
  position: fixed;
  right: 20px;
  bottom: 20px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  cursor: pointer;
`;

const Card = styled.div`
  width: 114px;
  height: 56px;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(75, 164, 133, 0.15);
  border-radius: 30px;
  border: 1px solid #4ba485;
  left: 0px;
  top: 0px;
  position: absolute;
`;

const Text = styled.div`
  color: #4ba485;
  font-size: 10px;
  font-family: 'Droid Sans', sans-serif;
  font-weight: 700;
  word-wrap: break-word;
  position: absolute; // To overlay on the Background
  padding: 8px;
  left: 42px;
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 23.87px;
  position: relative;
  margin-top: 4px; // Adjust as needed for positioning
`;

const BackgroundDesktop = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(75, 164, 133, 0.15);
  border-radius: 8px;
  border: 1px solid #4ba485;
  width: 208px;
  height: 56px;
  left: 0px;
  top: 0px;
  position: absolute;
`;

const TextDesktop = styled.div`
  color: #4ba485;
  font-size: 10px;
  font-family: 'Droid Sans', sans-serif;
  font-weight: 700;
  word-wrap: break-word;
  position: absolute;
  left: 50px; // Adjusted position for the desktop version
  top: 16px;
`;

const ContainerDesktop = styled.a`
  position: fixed;
  right: 20px;
  bottom: 70px;
  width: 208px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  cursor: pointer;
  z-index: 99;
`;

// Define a new component for the desktop version
const DesktopWhatsappButton = () => {
  const [isSeen, setIsSeen] = useState(true);
  if (!isSeen) {
    return null;
  }
  return (
    <ContainerDesktop href={WHATSAPP_GROUP_LINK}>
      <BackgroundDesktop style={{ background: 'white' }} />
      <BackgroundDesktop />
      <div
        style={{
          width: 24,
          height: 23.87,
          left: 0,
          top: 6,
          position: 'absolute',
        }}
      >
        <WhatsappIcon />
      </div>
      <TextDesktop>
        Join our WhatsApp group <br />
        and get updates
      </TextDesktop>
      <div
        style={{
          width: 12,
          height: 12,
          right: 8,
          top: 4,
          position: 'absolute',
        }}
      >
        <img
          src={'/Crosser.svg'}
          alt=""
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsSeen(false);
          }}
        ></img>
      </div>
    </ContainerDesktop>
  );
};
const WhatsappButton = () => {
  if (!isMobile()) {
    return DesktopWhatsappButton();
  }
  return (
    <Container href={WHATSAPP_GROUP_LINK}>
      <Card>
        <Background style={{ background: 'white' }} />
        <Background>
          <Text>
            Join our <br />
            WhatsApp <br />
            group
          </Text>
          <IconWrapper>
            <WhatsappIcon />
          </IconWrapper>
        </Background>
      </Card>
    </Container>
  );
};

export default WhatsappButton;
