import { atom } from 'recoil';
import { Rep } from '../hooks/repsData';

interface RepsAtom {
  reps: Rep[];
  repsFilter: string;
}

export const repsAtom = atom<RepsAtom>({
  key: 'repsAtom',
  default: {
    reps: [],
    repsFilter: '',
  },
});
