import styled from 'styled-components';

const SVG = styled.svg`
  width: 24px;
  height: 24px;
`;

export const HeartIcon = () => {
  return (
    <SVG viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M20.16 5.00017C19.1 3.93737 17.6948 3.28871 16.1984 3.17134C14.7019 3.05397 13.2128 3.47563 12 4.36017C10.7277 3.4138 9.14402 2.98468 7.56795 3.1592C5.99188 3.33373 4.54047 4.09894 3.506 5.30075C2.47154 6.50256 1.93085 8.05169 1.99283 9.63618C2.05481 11.2207 2.71485 12.7228 3.84003 13.8402L10.05 20.0602C10.57 20.5719 11.2704 20.8588 12 20.8588C12.7296 20.8588 13.43 20.5719 13.95 20.0602L20.16 13.8402C21.3276 12.6654 21.983 11.0764 21.983 9.42017C21.983 7.76389 21.3276 6.1749 20.16 5.00017ZM18.75 12.4602L12.54 18.6702C12.4694 18.7415 12.3853 18.7982 12.2926 18.8368C12.1999 18.8755 12.1005 18.8954 12 18.8954C11.8996 18.8954 11.8002 18.8755 11.7075 18.8368C11.6148 18.7982 11.5307 18.7415 11.46 18.6702L5.25003 12.4302C4.46579 11.6285 4.02664 10.5516 4.02664 9.43017C4.02664 8.3087 4.46579 7.23182 5.25003 6.43017C6.04919 5.64115 7.127 5.19873 8.25003 5.19873C9.37306 5.19873 10.4509 5.64115 11.25 6.43017C11.343 6.52389 11.4536 6.59829 11.5755 6.64906C11.6973 6.69983 11.828 6.72596 11.96 6.72596C12.092 6.72596 12.2227 6.69983 12.3446 6.64906C12.4665 6.59829 12.5771 6.52389 12.67 6.43017C13.4692 5.64115 14.547 5.19873 15.67 5.19873C16.7931 5.19873 17.8709 5.64115 18.67 6.43017C19.4651 7.22132 19.9186 8.29236 19.9336 9.41385C19.9485 10.5353 19.5237 11.6181 18.75 12.4302V12.4602Z"
        fill="currentColor"
      />
    </SVG>
  );
};
