import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createRoot } from 'react-dom/client';
import App from './App';
import './App.css';

const queryClient = new QueryClient();
// Initialize the dataLayer
// @ts-ignore
window.dataLayer = window.dataLayer || [];

// Trigger GTM
// @ts-ignore
window.dataLayer.push({
  'gtm.start': new Date().getTime(),
  event: 'gtm.js',
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>,
);
