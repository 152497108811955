import styled, { css } from 'styled-components';
import { CounterPath } from '../common/CounterPath';
import { useEffect, useState } from 'react';
import { MEDIA_QUERIES } from '../common/mediaQueries';
import { apiInstance } from '../../api/apiInstance';

const GoalWrapper = styled.div`
  background-color: var(--black);
  display: grid;
  gap: 8px;
  grid-template-columns: min(10vw, 100px) min(30vw, 200px) min(40vw, 300px);
  grid-template-rows: 1fr auto 1fr;
  width: 100%;
  color: var(--white);
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 100px 0 20px 0;

  ${MEDIA_QUERIES.laptop(css`
    padding: 80px 0;
  `)}

  ${MEDIA_QUERIES.tablet(css`
    padding: 60px 0;
    grid-template-columns: min(10vw, 70px) min(30vw, 140px) min(40vw, 220px);
    gap: 4px;
  `)}

  ${MEDIA_QUERIES.mobile(css`
    padding: 60px 100px 40px;
    grid-template-rows: repeat(3, auto) 180px 100px;
    grid-template-columns: 80vw;
    gap: 4px;
  `)}

  ${MEDIA_QUERIES.smallMobile(css`
    grid-template-rows: repeat(3, auto) 140px 80px;
    padding: 60px 100px;
  `)}
`;

const LeftText = styled.div`
  grid-column: 2 / 3;

  ${MEDIA_QUERIES.mobile(css`
    grid-column: 1/2;
    text-align: center;
  `)}
`;

const HelpText = styled(LeftText)`
  font-family: var(--font-secondary);
  font-size: 16px;
  align-self: end;
`;

const TargetText = styled(LeftText)`
  font-size: 48px;

  ${MEDIA_QUERIES.tablet(css`
    font-size: 42px;
  `)}

  ${MEDIA_QUERIES.smallMobile(css`
    font-size: 36px;
  `)}
`;

const GoalText = styled(LeftText)`
  font-size: 18px;
  align-self: start;
`;

const DailyText = styled(TargetText)`
  grid-column: 3 / 4;
  grid-row: 2/ 3;
  color: var(--red);
  text-align: center;

  ${MEDIA_QUERIES.mobile(css`
    grid-column: 1/2;
    grid-row: 4/5;
    align-self: end;
  `)}
`;

const TodayText = styled(GoalText)`
  grid-column: 3 / 4;
  grid-row: 3 / 4;
  text-align: center;

  ${MEDIA_QUERIES.mobile(css`
    grid-column: 1/2;
    grid-row: 5/6;
  `)}
`;

const goal = 250000;

const getFormattedNumber = (num: number) => {
  num = num === undefined ? 9500 : num; // overriding the number in case there's a backend error
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const Goal = () => {
  const [count, setCount] = useState(0);
  // State to indicate loading status
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    // Async function to fetch call count
    async function fetchCallCount() {
      try {
        // Start loading
        setIsLoading(true);
        // Make the API call
        const { data } = await apiInstance.get('/calls/count', { signal });
        // If the response is successful, update the call count
        if (data.success) {
          setCount(data.callCount);
        } else {
          console.error('Failed to fetch call count');
        }
      } catch (e) {
        // If there's an error, set the error state
        console.error('Failed to fetch call count', e);
      } finally {
        // Stop loading irrespective of the result
        setIsLoading(false);
      }
    }

    // Call the async function
    fetchCallCount();
    const intervalId = setInterval(fetchCallCount, 60000);
    // Cleanup function to clear the interval and abort the fetch
    return () => {
      clearInterval(intervalId);
      controller.abort();
    };
  }, []);

  return (
    <GoalWrapper>
      <HelpText>OUR GOAL</HelpText>
      <TargetText>{getFormattedNumber(goal)}</TargetText>
      <GoalText>Calls</GoalText>
      <DailyText>
        {isLoading ? 'Loading ....' : getFormattedNumber(count)}
      </DailyText>
      <TodayText>Calls placed</TodayText>
      <CounterPath />
      <CounterPath active percentage={count < goal ? count / goal : 1} />
    </GoalWrapper>
  );
};
