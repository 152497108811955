import React, { useCallback, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { authAtom } from '../../atom/authAtom';
import { useNotAuth } from './useAuth';
import { firstStepSignupApi } from '../../api/authApi';
import { validatePhone } from '../../util/dataValidation';
import { SubmitButton } from '../callerForm/CellFormStyles';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { PhoneCodeValidation } from './PhoneCodeValidation';
import styled from 'styled-components';
import { Input } from '../common/Input';

const LoginStyle = styled.div`
  margin: 30px auto;
  font-family: var(--font-secondary);
  max-width: 300px;
`;

const LogInTitle = styled.h2`
  text-align: center;
  margin-bottom: 10px;
`;

const TextStyle = styled.div`
  margin: 5px 0 10px 0;
  text-align: center;
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: center;

  input {
    text-align: center;
  }
`;

export const LoginForm = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isVerifyingAccount, setIsVerifyingAccount] = useState(false);
  const [{ isLoading }, setAuth] = useRecoilState(authAtom);
  useNotAuth();

  const isSubmitDisabled = useMemo(
    () => !validatePhone(phoneNumber),
    [phoneNumber],
  );

  const handleLogIn = useCallback(async () => {
    if (isSubmitDisabled) {
      return;
    }

    await firstStepSignupApi(phoneNumber, '', '', '', setAuth);
    setIsVerifyingAccount(true);
  }, [setAuth, phoneNumber, setIsVerifyingAccount, isSubmitDisabled]);

  const handleKeyDown = useCallback(
    () => (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleLogIn();
      }
    },
    [handleLogIn],
  );

  const loginForm = useMemo(
    () => (
      <>
        <TextStyle>Enter phone number to login</TextStyle>
        <InputContainer>
          <Input
            width="100px"
            light={true}
            hasError={false}
            placeholder="Phone number"
            onChange={(e) => setPhoneNumber(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </InputContainer>
        <SubmitButton disabled={isSubmitDisabled} onClick={handleLogIn}>
          {isLoading ? (
            <AiOutlineLoading3Quarters className="spinner" />
          ) : (
            'Submit'
          )}
        </SubmitButton>
      </>
    ),
    [setPhoneNumber, isSubmitDisabled, handleLogIn, isLoading, handleKeyDown],
  );

  return (
    <LoginStyle>
      <LogInTitle>Log in</LogInTitle>
      {!isVerifyingAccount ? (
        loginForm
      ) : (
        <PhoneCodeValidation phoneNumber={phoneNumber} />
      )}
    </LoginStyle>
  );
};
