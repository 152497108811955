import React from 'react';
import { UserDetailsForm } from './UserDetailsForm';
import { useRecoilValue } from 'recoil';
import { authAtom } from '../../atom/authAtom';
import styled from 'styled-components';

const UserDetailsPageStyle = styled.div`
  margin: auto;
  max-width: 200px;
  font-family: var(--font-secondary);
`;

const Title = styled.h2`
  margin-bottom: 20px;
`;

export const UserDetailsPage = () => {
  const { isLoading } = useRecoilValue(authAtom);
  return (
    <UserDetailsPageStyle>
      <Title>Settings</Title>
      {isLoading ? null : <UserDetailsForm />}
    </UserDetailsPageStyle>
  );
};
