import React from 'react';
import styled from 'styled-components';

const TOSPageWrapper = styled.div`
  width: 100dvw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Paragraph = styled.p`
  font-family: var(--font-secondary);
  font-size: 16px;
  text-align: justify;
  text-indent: 2em;
  width: 80%;
  margin-top: 18px;
`;

const Label = styled.p`
  font-family: var(--font-secondary);
  font-size: 16px;
  font-weight: 700;
  text-align: justify;
  text-indent: 0em;
  width: 80%;
  margin-top: 30px;
`;

const TextLink = styled.a`
  font-family: var(--font-secondary);
  font-size: 16px;
  color: #000;
  text-decoration: none;
`;

export const PrivacyPage: React.FC = () => {
  return (
    <TOSPageWrapper>
      <h1>PRIVACY POLICY</h1>

      <Paragraph>
        Your privacy is very important to One Minute a Day (“One Min a Day,”
        “we” or “us”). In order to best protect your information, we have
        adopted this privacy policy (“Privacy Policy”) which applies to any and
        all information about you that we collect. It is important to note that
        this Privacy Policy only applies to information that we collect through{' '}
        <TextLink>oneminaday.com</TextLink> and related websites that we own and
        control (the “Web Site”). Therefore, if you click any links on the Web
        Site that lead to web sites owned or operated by third parties or if you
        access any other web site, this Privacy Policy no longer applies.
        Moreover, the Web Site contains framed links to Facebook, Instagram and
        X (formerly known as Twitter). If you input information into these
        frames, that information is sent to those parties and is not controlled
        by us or governed by this Privacy Policy. You should review the privacy
        policies adopted by those third parties to ensure that you know what
        information is being collected and for what it is being used. Also note
        that the Web Site is not directed at children under the age of 18 years.
        While registration is not required in order to access the Web Site,
        certain features and areas of the Web Site require you to register,
        create an account and become a Member of One Min a Day (each, an “One
        Min a Day Member” and collectively, “One Min a Day Members”).
      </Paragraph>

      <Label>What Information We Collect and How We Collect It</Label>
      <Paragraph>
        Some of the information we collect is Personally Identifiable
        Information (“PII”). PII, collected only with your consent, may include
        your first and last name, mailing address, email address, telephone
        number, credit card and other payment information, images, videos and
        any other information that you provide to us that is linked to your
        identity. We will only collect PII that is relevant to the services that
        we provide and the maintenance of the Web Site. This may occur, for
        example, in connection with registering to become an One Min a Day
        Member, subscribing to any of our services, signing up to receive
        newsletters, responses you provide to survey questions, inquiries you
        may make through our Web Site, phone, mail or email and any other
        information you provide to us. Remember that you always have the option
        not to provide information by choosing not to use the Web Site or by not
        using the particular feature of the Web Site for which the information
        is being collected.
      </Paragraph>
      <Paragraph>
        We may also collect information through the use of “cookies”. Cookies
        are small pieces of data that are stored on your computer by your
        browser. This data helps our Web Site manage your experience while you
        visit and helps us determine whether you are a first-time visitor. You
        can always tell your Internet browser that you don’t want to have any
        cookies downloaded to your computer. Check with the provider of your
        particular browser to find out how. Before you do however, note that
        deleting cookies or directing your browser to refuse them may limit your
        ability to use certain portions of our Web Site that require cookies to
        function. Lastly, third parties who partner with us to display
        advertising and other content, may also use cookies to collect or store
        data.
      </Paragraph>
      <Paragraph>
        The last form of information we may collect is non-personally
        identifying information. As an example, we may collect information as
        visitors browse our Web Site such as the number of people that visit,
        what pages are visited and from where visitors come (like Google or
        Bing). We may also compile this data to enable statistical analysis of
        our services that would be used internally or with external assistance
        to make improvements to our Web Site. We may also provide this
        information to third parties, including advertisers. Remember though
        that this information is not personally identifiable.
      </Paragraph>

      <Label>Testimonials</Label>
      <Paragraph>
        We may display personal testimonials on our site in addition to other
        endorsements. With your consent we may post your testimonial along with
        your name. If you wish to update or delete your testimonial, you can
        contact us at support@oneminaday.com.
      </Paragraph>

      <Label>Links to 3rd Party Sites</Label>
      <Paragraph>
        Our Web Site includes links to other websites whose privacy practices
        may differ from . If you submit personal information to any of those
        sites, your information is governed by their privacy policies. We
        encourage you to carefully read the privacy policy of any website you
        visit.
      </Paragraph>

      <Label>California Do Not Track Procedures</Label>
      <Paragraph>
        We respect your choices about what PII we collect and what PII third
        parties can collect through us. Therefore, we will follow any
        Do-Not-Track instructions from your browser and any other direction that
        you give us regarding collection and dissemination of PII. Note however
        that any Do-Not-Track or similar mechanism will not affect our
        collection of the data that you voluntarily provide to us when signing
        up for any of our services, as detailed above, or when posting on any of
        our forums or blogs. This is also the case for third parties who wish to
        collect PII from us about you. Any information that you volunteer to us
        that is visible to the public could be collected by third parties. So,
        please do not provide us with any information that you do not want
        collected.
      </Paragraph>

      <Label>Our Use of Information</Label>
      <Paragraph>
        We may use the information we collect for a number of purposes. For
        instance we may use it to respond to your inquiries and otherwise
        correspond with you, to process transactions that you request, to
        maintain your account (if you have one), to maintain the security and
        integrity of our Web Site and services, to conduct market research or
        surveys, and for the administration, review and/or the improvement of
        the content of our Web Site and services. We may also use your
        information to communicate with you about services that we think may
        interest you. However, we will always give you the opportunity to opt
        out of receiving such communications as well as any newsletter for which
        you sign up.
      </Paragraph>

      <Label>Our Policy for Disclosing Your Information to Third Parties</Label>
      <Paragraph>
        Again, your privacy is important to us and as a result we are protective
        of your information. Except as stated above, there are only three
        instances in which we will disclose any of your information to anyone
        but you, as follows:
      </Paragraph>
      <Paragraph>
        1. To further our mission. We may transmit your information to third
        parties solely for the purpose of furthering and supporting our efforts
        to release the hostages captured by Hamas and held in Gaza, which
        includes transmitting certain information to, among other organizations,
        national and international hostages and missing families forums.
      </Paragraph>
      <Paragraph>
        2. With your consent. We may transmit your information to a third party
        when you give us permission to do so. This may occur when we complete a
        transaction that you request. This also may happen when you make your
        information publicly available by posting on our forums or blogs or in
        creating your personal profile (if you chose to). Because this
        information is public, we cannot control how others treat it and we ask
        that you exercise care in deciding what information you chose to share.
      </Paragraph>
      <Paragraph>
        3. If we are acquired by another company, that company will take on all
        responsibility for the personal information we collect and it will
        assume all rights and obligations with respect to that information.
        Should this happen, the acquiring company may develop its own policy
        with respect to your information.
      </Paragraph>
      <Paragraph>
        4. If we are required to disclose information by law. When directed by a
        court of law or other government entity or if we have a good faith basis
        to believe such action is necessary to comply with the law, we may be
        obligated to disclose personal information. In such event, we will
        comply with the law and make commercially reasonable efforts to notify
        you, if lawful.
      </Paragraph>
      <Paragraph>
        We do not sell or distribute PII to any third parties except as set
        forth above. However, all of the information that you disclose in your
        profile, blog posts, comments and other public portions of the Web Site
        becomes public information. Personal information in your One Min a Day
        Member profile is shared with other One Min a Day Members unless you
        specifically direct that your One Min a Day Member profile be anonymous.
        Bear in mind that if you wish to be anonymous, you may not be able to
        take advantage of some of the functionality of our Web Site. If you
        terminate your One Min a Day membership, we may retain your personal
        information for administrative purposes but will not display it publicly
        or share it in any other way.
      </Paragraph>

      <Label>Access to Your Information</Label>
      <Paragraph>
        We will provide you with access to PII you provide us for as long as we
        maintain that information in a readily accessible format. We will also
        work with you to correct any error in your PII. If you wish to access or
        correct any PII that you have submitted through our Web Site, or to have
        us completely remove your PII from our systems, please send an e-mail
        with your specific request to support@oneminaday.com. You may change
        visibility of your personal information throughout the site by editing
        your personal profile.
      </Paragraph>

      <Label>Security</Label>
      <Paragraph>
        We have in place physical, administrative and electronic safeguards to
        keep your information safe. For instance, we only allow access to
        employees who require your information to do their jobs and we use
        industry standard SSL encryption to secure your data.
      </Paragraph>

      <Label>Changes to the Privacy Policy</Label>
      <Paragraph>
        We may make changes to this Privacy Policy from time to time. If we do,
        we’ll be sure to try to notify you at the email address you have
        provided us and to post the date of the latest revision herein.
      </Paragraph>

      <Label>Contact Us </Label>
      <Paragraph>
        Thank you for visiting{' '}
        <TextLink href="https://oneminaday.com">oneminaday.com</TextLink>
      </Paragraph>
      <Paragraph>
        If you have questions about our Privacy Policy please contact us at{' '}
        <TextLink href="mailto:support@oneminaday.com">
          support@oneminaday.com
        </TextLink>
      </Paragraph>
      <Paragraph>Last Modified: November 13, 2023</Paragraph>
    </TOSPageWrapper>
  );
};
