import styled, { css } from 'styled-components';
import { MEDIA_QUERIES, MobileBR } from '../common/mediaQueries';

const InstructionsWrapper = styled.div`
  display: grid;
  column-gap: 80px;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
  text-align: center;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(3, 1fr);

  ${MEDIA_QUERIES.laptop(css`
    padding: 40px 0;
    column-gap: 50px;
  `)}

  ${MEDIA_QUERIES.tablet(css`
    padding: 35px 0;
    column-gap: 20px;
  `)}

  ${MEDIA_QUERIES.smallTablet(css`
    padding: 35px 0;
    column-gap: 40px;
  `)}

  ${MEDIA_QUERIES.mobile(css`
    grid-template-rows: repeat(6, 1fr);
    grid-template-columns: 1fr;
  `)}
`;

const Title = styled.div`
  grid-row: 1/2;
  font-size: 48px;
  font-weight: 700;
  color: var(--red);

  ${MEDIA_QUERIES.laptop(css`
    font-size: 32px;
  `)}

  ${MEDIA_QUERIES.mobile(css`
    grid-row: unset;
    font-size: 36px;
  `)}

  ${MEDIA_QUERIES.smallMobile(css`
    font-size: 32px;
  `)}
`;

const Subtitle = styled.div`
  align-self: start;
  grid-row: 2/3;
  font-family: var(--font-secondary);

  ${MEDIA_QUERIES.smallTablet(css`
    font-size: 14px;
  `)}

  ${MEDIA_QUERIES.mobile(css`
    grid-row: unset;
  `)}
`;

export const Callout = () => {
  return (
    <InstructionsWrapper>
      <Title>1 minute</Title>
      <Subtitle>of your day</Subtitle>
      <Title>Every Call</Title>
      <Subtitle>counts</Subtitle>
      <Title>Save Lives</Title>
      <Subtitle>
        Use your <MobileBR />
        power now!
      </Subtitle>
    </InstructionsWrapper>
  );
};
