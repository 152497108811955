import { apiInstance } from './apiInstance';
import { SetterOrUpdater } from 'recoil';
import { AuthAtom, DEFAULT_USER_DETAILS } from '../atom/authAtom';
import { TryAuthResponse } from 'oneminuteaday-common';

export const firstStepSignupApi = async (
  phoneNumber: string,
  zip: string,
  userName: string,
  email: string,
  setAuth: SetterOrUpdater<AuthAtom>,
) => {
  try {
    setAuth((auth) => ({ ...auth, isLoading: true }));
    await apiInstance.post(`/auth/signup`, {
      phoneNumber,
      zip,
      userName,
      email,
    });
  } catch (e) {
  } finally {
    setAuth((auth) => ({ ...auth, isLoading: false }));
  }
};

export const secondStepSignupApi = async (
  phoneNumber: string,
  verificationCode: string,
  setAuth: SetterOrUpdater<AuthAtom>,
) => {
  try {
    setAuth((auth) => ({ ...auth, isLoading: true }));
    const { displayName, zip } = await apiInstance
      .post<TryAuthResponse>(`/auth/verify`, {
        phoneNumber,
        verificationCode,
      })
      .then((r) => r.data);
    setAuth((auth) => ({
      ...auth,
      isAuth: true,
      userDetails: { phoneNumber, displayName, zip },
    }));
  } catch (e) {
    setAuth((auth) => ({ ...auth, errorMessage: 'Wrong code, try again.' }));
  } finally {
    setAuth((auth) => ({ ...auth, isLoading: false }));
  }
};

export const tryAuthApi = async (setAuth: SetterOrUpdater<AuthAtom>) => {
  try {
    setAuth((auth) => ({ ...auth, isLoading: true }));
    const { displayName, fail, zip, phoneNumber, email } = await apiInstance
      .get<TryAuthResponse>(`/auth/test`)
      .then((r) => r.data);
    setAuth((auth) => ({
      ...auth,
      isAuth: !fail,
      userDetails: { displayName, zip, phoneNumber, email },
    }));
  } catch (e) {
  } finally {
    setAuth((auth) => ({ ...auth, isLoading: false, triedInitialAuth: true }));
  }
};

export const logoutApi = async (setAuth: SetterOrUpdater<AuthAtom>) => {
  setAuth((old) => ({
    ...old,
    isAuth: false,
    userDetails: DEFAULT_USER_DETAILS,
  }));
  await apiInstance.get(`/auth/logout`);
};

export const updateUserDetails = async (
  displayName: string,
  zip: string,
  email: string,
  setAuth: SetterOrUpdater<AuthAtom>,
) => {
  await apiInstance.post<TryAuthResponse>(`/auth/update`, {
    displayName,
    zip,
    email,
  });
  setAuth((old) => ({
    ...old,
    userDetails: { ...old.userDetails, displayName, zip },
  }));
};
