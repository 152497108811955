import { atom } from 'recoil';
import { LeaderboardRecord } from 'oneminuteaday-common';

export interface LeaderboardAtom {
  isLoaded: boolean;
  records: LeaderboardRecord[];
}

export const leaderboardAtom = atom<LeaderboardAtom>({
  key: 'leaderboardAtom',
  default: {
    isLoaded: false,
    records: [],
  },
});
