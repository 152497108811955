import { CallVolunteerForm } from './CallerFormModal';
import { validateEmail, validatePhone } from '../../util/dataValidation';

export const validateForm = (form: CallVolunteerForm) => {
  const { fullName, email, phoneNumber } = form;
  const fullNameLength = fullName.length;
  const fullNameValid = fullNameLength > 2 && fullNameLength < 30;
  const emailValid = validateEmail(email);
  const validPhone = validatePhone(phoneNumber);

  return fullNameValid && emailValid && validPhone;
};

export const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
