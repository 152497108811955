import { atom } from 'recoil';

export interface UserDetails {
  displayName: string;
  phoneNumber: string;
  zip?: string;
  email?: string;
}

export interface AuthAtom {
  triedInitialAuth: boolean;
  isAuth: boolean;
  isLoading: boolean;
  errorMessage: string | undefined;
  zip?: string;
  userDetails: UserDetails;
}

export const DEFAULT_USER_DETAILS = {
  displayName: '',
  phoneNumber: '',
  zip: '',
  email: '',
};

export const authAtom = atom<AuthAtom>({
  key: 'auth',
  default: {
    triedInitialAuth: false,
    isAuth: false,
    isLoading: true,
    errorMessage: undefined,
    userDetails: DEFAULT_USER_DETAILS,
  },
});
