import styled from 'styled-components';
import { RedButton } from '../common/Button';

export const FormStyle = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ModalContainer = styled.div`
  padding: 20px;
  font-size: 20px;
`;
export const Description = styled.div`
  margin: 40px 0;
  text-align: center;
`;
export const Subtitle = styled.h3`
  margin-top: 20px;
`;

export const CheckboxLabel = styled.label`
  padding: 4px 6px;
  border-radius: 8px;
  font-family: var(--font-secondary);
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background: var(--light-gray);
    color: black;
  }
`;

export const Checkbox = styled.input`
  visibility: hidden;
  width: 0;

  &:checked + label {
    background: white;
    color: black;
  }
`;

export const SubmitButton = styled(RedButton)`
  font-size: 18px;
  box-shadow: unset;
  &:hover {
    color: var(--light-gray);
  }

  &:enabled {
    cursor: pointer;
  }
`;

export const CloseButton = styled.div`
  margin-bottom: 10px;
  text-align: right;
  color: var(--red);
  cursor: pointer;
`;

export const Title = styled.h2`
  text-align: center;
`;
