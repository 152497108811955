import React from 'react';
import { Leaderboard } from './leaderboard/Leaderboard';
import styled from 'styled-components';
import { SCALE_2 } from '../common/theme';
import { useForceAuth } from '../auth/useAuth';

export const DashboardStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SCALE_2};
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 30px 0;
`;

export const Dashboard = () => {
  const isAuth = useForceAuth();

  if (!isAuth) {
    return null;
  }

  return (
    <DashboardStyle>
      <Leaderboard />
    </DashboardStyle>
  );
};
