import React, { useEffect, useMemo, useState } from 'react';
import hostages from './hostages.json';
import styled, { css } from 'styled-components';
import { MEDIA_QUERIES } from '../common/mediaQueries';

const INTERVAL = 2000;

const Details = styled.div`
  background: #d53e22b5;
  color: white;
  font-size: 18px;
  text-align: center;
  padding: 8px;
  position: absolute;
  width: 100%;
  bottom: 0;
  max-width: 400px;

  ${MEDIA_QUERIES.laptop(css`
    width: 100%;
  `)}
`;

const HostagesCarouselStyle = styled.div`
  position: relative;
  border-radius: 14px;
  overflow: hidden;
  max-width: 400px;
  grid-row: 1/ 3;
  width: 400px;

  img {
    display: block;
    height: 400px;
  }

  ${MEDIA_QUERIES.smallTablet(css`
    width: auto;
  `)}
`;

export const HostagesCarousel = () => {
  const [isPlay, setIsPlay] = useState(true);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (isPlay) {
      interval = setInterval(
        () => setIndex((i) => (i < hostages.length - 1 ? i + 1 : 0)),
        INTERVAL,
      );
    }

    return () => !!interval && clearInterval(interval);
  }, [isPlay, setIndex]);

  const { name, fileName, age } = useMemo(() => hostages[index], [index]);

  return (
    <HostagesCarouselStyle
      onMouseEnter={() => setIsPlay(false)}
      onMouseLeave={() => setIsPlay(true)}
    >
      {fileName && <img src={`./hostages/${fileName}`} alt={name} />}
      <Details>
        {name}, {age}
      </Details>
    </HostagesCarouselStyle>
  );
};
