import { createBrowserRouter } from 'react-router-dom';
import { PageContainer } from './PageContainer';
import { LandingPage } from '../landingPage/LandingPage';
import React from 'react';
import { TOSPage } from '../tosPage/TOSPage';
import { PrivacyPage } from '../privacyPage/PrivacyPage';
import { CallPage } from '../callPage/CallPage';
import { SignupForm } from '../auth/SignupForm';
import { CallerFormPage } from '../callerForm/CallerFormFullPage';
import { RemindersPage } from '../dashboard/reminder/RemindersPage';
import { Logout } from './Logout';
import { UserDetailsPage } from '../auth/UserDetailsPage';
import { LoginForm } from '../auth/LoginForm';
import { Leaderboard } from '../dashboard/leaderboard/Leaderboard';

export const DASHBOARD_ROUTE_BASE = '/dashboard';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <PageContainer />,
    children: [
      { path: '', element: <LandingPage /> },
      { path: 'logout', element: <Logout /> },
      { path: 'tos', element: <TOSPage /> },
      { path: 'privacy', element: <PrivacyPage /> },
      { path: 'signup', element: <SignupForm /> },
      { path: 'login', element: <LoginForm /> },
      {
        path: '/call/:id/:call',
        element: <CallPage />,
      },
      { path: DASHBOARD_ROUTE_BASE, element: <RemindersPage /> },
      {
        path: `${DASHBOARD_ROUTE_BASE}/settings`,
        element: <UserDetailsPage />,
      },
      {
        path: `${DASHBOARD_ROUTE_BASE}/leaderboard`,
        element: <Leaderboard />,
      },
    ],
  },
  {
    // This is only used on mobile.
    // TODO: Remove this route after refactoring
    path: '/volunteer',
    element: <CallerFormPage />,
  },
]);
