import React, { useCallback } from 'react';
import { Modal } from '../common/Modal';
import { ModalContainer } from './CellFormStyles';
import { CallerFormContent } from './CallerFormContent';
import { useSearchParams } from 'react-router-dom';

export interface CallVolunteerForm {
  fullName: string;
  email: string;
  phoneNumber: string;
  hearAboutUs?: string;
}

export interface CallerFormModalProps {
  onCloseCallerFormModal: () => void;
  isOpen: boolean;
}

export const CallerFormModal = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const show = searchParams.get('showVolunteerForm') === 'true';

  const handleClose = useCallback(() => {
    setSearchParams((params: any) => {
      params.delete('showVolunteerForm');
      return params;
    });
  }, [setSearchParams]);

  return (
    <Modal open={show}>
      <ModalContainer>
        <CallerFormContent onCloseForm={handleClose} />
      </ModalContainer>
    </Modal>
  );
};
