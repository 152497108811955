import styled, { RuleSet, css } from 'styled-components';

export const MEDIA_QUERIES = {
  smallMobile: (rules: RuleSet<object>) => css`
    @media (max-width: 320px) {
      ${rules}
    }
  `,
  mobile: (rules: RuleSet<object>) => css`
    @media (max-width: 450px) {
      ${rules}
    }
  `,
  smallTablet: (rules: RuleSet<object>) => css`
    @media (max-width: 620px) {
      ${rules}
    }
  `,
  tablet: (rules: RuleSet<object>) => css`
    @media (max-width: 768px) {
      ${rules}
    }
  `,
  laptop: (rules: RuleSet<object>) => css`
    @media (max-width: 1024px) {
      ${rules}
    }
  `,
};

export const MobileBR = styled.br`
  display: none;
  ${MEDIA_QUERIES.smallTablet(css`
    display: block;
  `)}
`;
