/* eslint-disable no-useless-escape */

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePhone = (phone: string) => {
  const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return regex.test(phone);
};

export const validateZip = (zip?: string) =>
  !!zip ? /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip) : false;

export const validDisplayName = (displayName?: string) =>
  displayName ? displayName.length > 2 && displayName.length < 20 : false;
