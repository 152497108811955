import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import styled, { css } from 'styled-components';
import {
  constituent,
  formatPhone,
  introText,
  mainText,
  voicemail,
} from './utils';
import { PhoneIcon } from '../common/PhoneIcon';
import { MEDIA_QUERIES, MobileBR } from '../common/mediaQueries';
import { CallPageActions } from './CallPageActions';
import { addCall } from '../../api/repsApis';
import { isMobile } from '../../utils';
import { useRecoilValue } from 'recoil';
import { repsAtom } from '../../atom/repsAtom';
import { Rep, repsData } from '../../hooks/repsData';
import {
  ReminderModalType,
  useReminderForm,
} from '../reminder/useReminderForm';

const CallPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  position: relative;
  padding: 0 20px;
`;

const CallNowWrapper = styled.a`
  width: 100vw;
  background-color: var(--red);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  color: var(--white);
  padding: 24px 0;
  cursor: pointer;
  text-decoration: none;
`;

const CallCounter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  font-size: 28px;
  gap: 8px;
  margin-top: 30px;

  & span:last-of-type {
    font-family: var(--font-secondary);
    font-size: 20px;
  }

  ${MEDIA_QUERIES.mobile(css`
    font-size: 22px;

    & span:last-of-type {
      font-size: 16px;
    }
  `)}
`;

const RepName = styled.div`
  font-size: 60px;
  margin-top: 20px;
  font-weight: 700;

  & span {
    font-size: 0.3em;
  }

  ${MEDIA_QUERIES.tablet(css`
    font-size: 50px;

    & span {
      font-size: 0.4em;
    }
  `)}

  ${MEDIA_QUERIES.mobile(css`
    font-size: 40px;
  `)}
`;

const RepTitle = styled.div`
  font-family: var(--font-secondary);
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;

  ${MEDIA_QUERIES.tablet(css`
    font-size: 16px;
    margin-bottom: 20px;
  `)}
`;

const PhoneWrapper = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: black;
  display: flex;
  align-items: center;
  font-size: 100px;

  & span {
    margin-top: -3%;
    margin-left: 20px;
  }

  ${MEDIA_QUERIES.laptop(css`
    font-size: 80px;
  `)}

  ${MEDIA_QUERIES.tablet(css`
    font-size: 70px;
    margin-bottom: 16px;

    & span {
      margin-left: 16px;
    }
  `)}

  ${MEDIA_QUERIES.smallTablet(css`
    font-size: 50px;

    & span {
      margin-left: 0;
    }
  `)}

  ${MEDIA_QUERIES.mobile(css`
    font-size: 45px;
    margin-bottom: 8px;
  `)}

  ${MEDIA_QUERIES.smallMobile(css`
    font-size: 40px;
  `)}
`;

const QRPhoneCall = styled.img`
  margin-bottom: 20px;
`;

const ScriptCallWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0 30px;
  max-width: min(900px, 90vw);
  width: 100vw;
  padding: 0 40px;

  ${MEDIA_QUERIES.smallTablet(css`
    max-width: 100vw;
  `)}
`;

const ScriptTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

const ScriptPartWrapper = styled.div`
  width: 100%;
  margin: 15px 15px;

  ${MEDIA_QUERIES.smallTablet(css`
    margin: 15px 0;
  `)}
`;

const ScriptPartTitle = styled.div`
  grid-column: 2/3;
  grid-row: 2/3;
  display: flex;
  flex-direction: column;
  font-size: 20px;
`;

const ScriptPartContent = styled.div`
  grid-column: 2/3;
  grid-row: 3/4;
  font-family: var(--font-secondary);
`;

export const CallPage = () => {
  const { reps } = useRecoilValue(repsAtom);
  const { id, call } = useParams();
  const navigate = useNavigate();
  const [localReps, setLocalReps] = useState<Rep[]>([]);
  const { reminderModal, setModalType } = useReminderForm();

  if (!call) {
    navigate(`/call/${id}/0`);
  }

  const callIndex = parseInt(call ?? '0');

  useEffect(() => {
    // The user can select any reps to be the first.Hence, sort the local array to determine a new first rep.
    if (callIndex === 0 && !localReps.length) {
      const newLocalReps = [...reps].sort((a) => (a._id === id ? -1 : 1));
      if (newLocalReps.length) {
        setLocalReps(newLocalReps);
      }
    }
  }, [callIndex, localReps, setLocalReps, reps, id]);

  const rep = useMemo(() => repsData.find(({ _id }) => _id === id), [id]);

  const handledCalled = useCallback(() => {
    const callInfo = {
      rep_id: reps[callIndex]._id,
      confirmed: true,
    };
    addCall(callInfo);

    // @ts-ignore
    window.dataLayer.push({
      event: 'call_confirmed',
      rep_id: reps[callIndex]._id,
    });

    if (callIndex === reps.length - 1) {
      setModalType(ReminderModalType.THANK_YOU);
      return;
    }

    const nextIndex = callIndex + 1;
    const nextRep = reps[nextIndex];
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(`/call/${nextRep._id}/${nextIndex}`);
  }, [callIndex, navigate, reps, setModalType]);

  if (!rep) {
    // This is a temporary state while loading the local reps.
    return null;
  }

  const numOfCalls = reps.length;
  const numOfCallsLeft = numOfCalls - callIndex;

  const { phone, full_name, title, _id, party } = rep;

  const normalizedPhone = formatPhone(phone, true);
  const callHref = `tel:${normalizedPhone}`;
  const phoneDisplay = normalizedPhone.replace('+', '');

  const scriptParts = [
    {
      content: introText,
    },
    {
      content: constituent(rep),
    },
    {
      content: mainText(rep),
    },
    {
      title: 'Note',
      content: voicemail,
    },
  ];

  return (
    <CallPageWrapper>
      <CallNowWrapper className={'call-now-btn'} href={callHref}>
        CALL NOW
      </CallNowWrapper>
      {/* <GridWrapper> */}
      {/* <FlexColumnWrapper> */}
      {numOfCalls > 1 ? (
        <CallCounter>
          {numOfCallsLeft === 0 ? (
            'You called all the reps in your area! Thank you for your help! See you tomorrow!'
          ) : (
            <>
              <span>{`Call ${callIndex + 1} of ${numOfCalls}`}</span>
              <span>
                {numOfCallsLeft > 1
                  ? `Just ${numOfCalls - callIndex} calls to go!`
                  : 'Last call!'}
              </span>
            </>
          )}
        </CallCounter>
      ) : null}
      <RepName>
        {full_name} <span>({party[0]})</span>
      </RepName>
      <RepTitle>
        {title.split('from')[0]} from <MobileBR />
        {title.split('from')[1]}
      </RepTitle>
      <PhoneWrapper className={'phone-num-btn'} href={callHref}>
        <PhoneIcon big />
        <span>+</span>
        {phoneDisplay}
      </PhoneWrapper>
      {!isMobile() && (
        <QRPhoneCall
          onError={(e: any) => (e.target.style.display = 'none')}
          src={'/qr/' + _id + '.png'}
          alt={`QR code to call ${full_name}`}
        />
      )}
      {/* </FlexColumnWrapper> */}
      <ScriptCallWrapper>
        <ScriptTitle>Call Script</ScriptTitle>
        {scriptParts.map(({ title, content }, index) => (
          <ScriptPartWrapper>
            {title && <ScriptPartTitle>{title}</ScriptPartTitle>}
            <ScriptPartContent>{content}</ScriptPartContent>
          </ScriptPartWrapper>
        ))}
      </ScriptCallWrapper>
      {/* </GridWrapper> */}
      {reminderModal}
      <CallPageActions
        onCalled={handledCalled}
        row
        onRemind={() => setModalType(ReminderModalType.FORM)}
      />
    </CallPageWrapper>
  );
};
