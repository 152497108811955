import { useCallback, useEffect, useMemo, useState } from 'react';

export function useCheckMobileScreen() {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = useCallback(() => {
    setWidth(window.innerWidth);
  }, [setWidth]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [handleWindowSizeChange]);

  return useMemo(() => width <= 768, [width]);
}
