import React, { useState } from 'react';
import { Modal } from '../common/Modal';
import styled, { css } from 'styled-components';
import { MEDIA_QUERIES } from '../common/mediaQueries';
import { ShareToSocial } from '../common/Share';
import { Button } from '../common/Button';
import { useSetRecoilState } from 'recoil';
import { zipCodeModalAtom } from '../../atom/zipCodeModalAtom';
import { Link } from 'react-router-dom';

const Title = styled.div`
  font-size: 44px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  margin-block-end: 24px;
  color: var(--red);

  ${MEDIA_QUERIES.smallTablet(css`
    font-size: 40px;
    margin-block-end: 18px;
  `)}

  ${MEDIA_QUERIES.mobile(css`
    font-size: 32px;
  `)}
`;

const Subtitle = styled.div`
  font-family: var(--font-secondary);
  text-align: center;
  margin-block-end: 60px;
  line-height: 1.4;

  ${MEDIA_QUERIES.smallTablet(css`
    margin-block-end: 40px;
  `)}

  ${MEDIA_QUERIES.mobile(css`
    font-size: 13px;
    margin-block-end: 30px;
  `)}
`;

const P = styled.p`
  font-size: 12px;
  margin-block-end: 10px;
`;

const RedButton = styled(Button)`
  background-color: var(--red);
  color: var(--white);
  box-shadow: 0 0 0;
  border-radius: 25px;
`;

const SignupLink = styled(Link)`
  font-size: 14px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
  margin-block-end: 50px;
`;

const Share = styled(ShareToSocial)`
  width: 100%;
`;

export const PromptModal = () => {
  const [showModal, setModal] = useState(true);

  const close = () => {
    setModal(false);
  };

  const url = 'https://oneminaday.com';
  const sharedText =
    'I donated my one minute today to save their lives! Have you?';

  const setZipCodeModal = useSetRecoilState(zipCodeModalAtom);

  const promptModal = (
    <Modal
      open={showModal}
      close={close}
      showCloseButton
      withPadding
      isCentered
    >
      <Title>You have more power than you realize</Title>
      <Subtitle>
        Every call you make to your elected officials, whether you speak to
        someone or leave a message, is tallied by a congressional aide. Every
        call counts.
        <br></br>
        <br></br>
        Make a quick call or leave a voicemail and demand the immediate release
        of all the hostages in Gaza. An easy and simple act, just 1 min of your
        day, is the most effective action you can do. Together, we will bring
        them home.
      </Subtitle>
      <RedButton
        onClick={() => {
          setZipCodeModal((old) => ({ ...old, showZipModal: true }));
          close();
        }}
      >
        &nbsp;&nbsp;Call now&nbsp;&nbsp;
      </RedButton>
      <br></br>

      <Subtitle>
        <SignupLink
          to="/signup"
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          Sign up
        </SignupLink>{' '}
        for daily reminders
        <br></br>
        <br></br>
        <P>ENCOURAGE YOUR FRIENDS TO CALL!</P>
        <Share network="FB" text={sharedText} url={url} />
        &nbsp;&nbsp;
        <Share network="X" text={sharedText} url={url} hashtag={'1MINADAY'} />
        &nbsp;&nbsp;
        <Share
          network="LI"
          text={sharedText + ' ' + url + ' #1MINADAY'}
          url={url}
        />
      </Subtitle>
    </Modal>
  );

  return promptModal;
};
